var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"same-container"},[_c('div',{staticClass:"size-container"},[_c('div',{class:_vm.navName === '名单公示' ? 'public-container' : 'list-container'},[(
            _vm.showIndex &&
              (_vm.navName === '新闻动态' ||
                _vm.navName === '四化政策' ||
                _vm.navName === '名单公示' ||
                _vm.navName === '行业解决方案')
          )?_c('div',{staticClass:"add-container",on:{"click":_vm.handleAdd}},[_c('div',{staticClass:"add-content pointer"},[_c('img',{attrs:{"src":_vm._f("staticMedia")('/addContent.png'),"alt":""}}),_vm._v(" 添加内容 ")])]):_vm._e(),(_vm.navName === '四化诊断' || _vm.navName === '数字化诊断')?_c('div',[_c('fourDiagnosis')],1):_vm._e(),(_vm.navName === '四化改造')?_c('fourRemodel'):_vm._e(),_vm._l((_vm.dataList),function(item,index){return _c('div',{key:index * _vm.currentPage,staticClass:"list-content",on:{"click":function($event){return _vm.handleDetail(item)}}},[(
              _vm.showIndex &&
                (_vm.navName === '新闻动态' || _vm.navName === '行业解决方案')
            )?_c('div',{staticClass:"news-item"},[_c('newsUpdatesItem',{attrs:{"itemDetail":item},on:{"editContent":_vm.handleAdd,"openDeleteDialog":function($event){return _vm.openDeleteDialog(item.id)}}})],1):_vm._e(),(_vm.showIndex && _vm.navName === '四化政策')?_c('div',{staticClass:"news-item"},[_c('fourPoliciesItem',{attrs:{"itemDetail":item},on:{"editContent":_vm.handleAdd,"openDeleteDialog":function($event){return _vm.openDeleteDialog(item.id)}}})],1):_vm._e(),(_vm.showIndex && _vm.navName === '名单公示')?_c('div',{staticClass:"news-item"},[_c('listPublicItem',{attrs:{"itemDetail":item},on:{"editContent":_vm.handleAdd,"openDeleteDialog":function($event){return _vm.openDeleteDialog(item.id)}}})],1):_vm._e(),(_vm.showIndex && _vm.navName === '优秀案例')?_c('div',{staticClass:"news-item"},[_c('excellentCaseItem',{attrs:{"itemDetail":item,"dataList":_vm.dataList,"isSelectedAll":_vm.isSelectedAll},on:{"editContent":_vm.handleAdd,"setSelectedId":_vm.getSelectedId,"openDeleteDialog":function($event){return _vm.openDeleteDialog(item.id)}}})],1):_vm._e()])}),(
            _vm.showIndex &&
              this.total > 10 &&
              (_vm.navName === '新闻动态' ||
                _vm.navName === '四化政策' ||
                _vm.navName === '行业解决方案' ||
                _vm.navName === '优秀案例' ||
                _vm.navName === '名单公示')
          )?_c('Pagination',{staticClass:"pagination",attrs:{"total":_vm.total,"currentPage":_vm.currentPage,"pageSize":+_vm.pageSize,"layout":'prev, pager, next'},on:{"pageChange":_vm.getList}}):_vm._e(),(
            _vm.showIndex && _vm.navName === '优秀案例' && this.dataList.length > 0
          )?_c('div',{staticClass:"checkbox-container"},[_c('div',{staticClass:"check-all",on:{"click":_vm.handleCheck}},[_c('div',{staticClass:"checkbox"},[_c('img',{attrs:{"src":_vm._f("staticMedia")(this.isSelectedAll
                    ? 'choose.png'
                    : 'nochoose.png'),"alt":""}})]),_c('div',{},[_vm._v("全选")])]),_c('el-button',{staticClass:"widthBtn btn primaryBtn1",on:{"click":_vm.handlePublish}},[_vm._v("发布")])],1):_vm._e(),(!_vm.showIndex)?_c('div',{staticClass:"add-item"},[_c('addContentItem',{attrs:{"addOrEdit":_vm.add ? '添加' : '修改',"navName":_vm.navName,"editId":_vm.editId},on:{"addSuccess":_vm.addSuccess}})],1):_vm._e()],2)])]),_c('deleteDialog',{ref:"delDialogShow",on:{"delSuccess":_vm.handleDelSuccess}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }