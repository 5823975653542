import { render, staticRenderFns } from "./CenterHeader.vue?vue&type=template&id=0e2ecd01&scoped=true&"
import script from "./CenterHeader.vue?vue&type=script&lang=js&"
export * from "./CenterHeader.vue?vue&type=script&lang=js&"
import style0 from "./CenterHeader.vue?vue&type=style&index=0&id=0e2ecd01&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e2ecd01",
  null
  
)

export default component.exports