<template>
  <div class="qiyeba">
    <div>
      <el-button class="primaryBtn1 btn ml20" @click="showqyform"
        >录入企业备案</el-button
      >
      <el-button class="primaryBtn1 btn ml20" @click="showTable_list"
        >查看企业备案表</el-button
      >
      <el-button class="primaryBtn1 btn ml20" @click="exportDiagnose"
        >导出四化诊断进度</el-button
      >
    </div>

    <!-- 企业表单 -->
    <el-dialog
      :title="QYFormTitle"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      center
      width="750px"
      top="10vh"
      :before-close="handleCloseDialog"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="150px"
        class="demo-ruleForm"
        label-position="right"
      >
        <el-form-item label="被诊断企业名称" prop="company_name_temp">
          <el-select
            ref="selectCompany"
            v-model="ruleForm.company_name_temp"
            clearable
            filterable
            remote
            :remote-method="getDatas"
            :loading="loading"
            placeholder="请选择"
            @change="compychange"
          >
            <el-option
              v-for="item in compylist"
              :key="item.id"
              :label="item.company"
              :value="{ value: item.code, label: item.company }"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="机构代码" prop="code">
          <el-input v-model="ruleForm.code" disabled></el-input>
        </el-form-item>
        <el-form-item label="诊断机构名称" prop="zd_qyName">
          <el-input v-model="ruleForm.zd_qyName" disabled></el-input>
        </el-form-item>
        <el-form-item label="诊断机构联系人" prop="contact">
          <el-input v-model="ruleForm.contact"></el-input>
        </el-form-item>
        <el-form-item label="诊断机构联系方式" prop="phone">
          <el-input v-model="ruleForm.phone"></el-input>
        </el-form-item>
        <el-form-item label="企业地点" prop="areaData">
          <!-- <AreaSelect :defVal="areaData" @onAreaChange="handleAreaChange"></AreaSelect> -->
          <AreaSelect :defVal="areaData" @submitArea="getAreaData"></AreaSelect>
        </el-form-item>
        <el-form-item label="产品类型" prop="product_type">
          <el-input v-model="ruleForm.product_type"></el-input>
        </el-form-item>
        <el-form-item label="所属行业" prop="trade_id">
          <el-select
            v-model="ruleForm.trade_id"
            class="form-input"
            placeholder="请选择"
          >
            <el-option
              v-for="(item, index) in hylist"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input type="textarea" v-model="ruleForm.remark"></el-input>
        </el-form-item>
        <el-form-item label="合同文件" prop="contract_url">
          <el-upload
            :action="uploadUrl"
            :on-success="handleUploadSuccess"
            :on-exceed="handleExceed"
            :on-preview="handlePreview"
            :file-list="fileList"
            :limit="1"
          >
            <el-button size="small" type="success" :disabled="isDetail"
              >上传</el-button
            >
            <div slot="tip" class="el-upload__tip" style="text-wrap: nowrap;">
              注:最多可上传1个文件,文件上传大小不能超过20M
            </div>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button class="primaryBtn1" @click="submitForm('ruleForm')"
          >确 定</el-button
        >
        <el-button class="cancleBtn1" @click="resetForm('ruleForm')"
          >取 消</el-button
        >
      </div>
    </el-dialog>
    <!-- 企业列表 -->
    <el-dialog
      title="查看备案企业列表"
      :visible.sync="dialogTableVisible"
      :before-close="hidepa"
      :close="hidepa"
      center
      width="90%"
      top="3vh"
      :close-on-click-modal="false"
    >
      <div class="qiyetop" style="margin-bottom: 20px;">
        <div class="qiye_top_souguo">
          <div class="search_box">
            <img :src="'search_icon.png' | staticMedia" class="search_icon" />
            <el-input
              class="form-input"
              type="text"
              v-model="search"
              value=""
              placeholder="请输入需要查询的企业名称"
            />
          </div>
          <el-button class="search-btn" @click="Search_Table_list()"
            >查询</el-button
          >
        </div>
      </div>
      <div class="tablelist">
        <el-table
          ref="multipleTable"
          border
          :data="tableData.data"
          tooltip-effect="dark"
          style="width: 100%"
        >
          <el-table-column
            label="序号"
            type="index"
            width="50"
            align="center"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="company_name"
            label="公司名称"
            align="center"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="code"
            label="机构代码"
            width="180"
            align="center"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            label="行政区域"
            width="150"
            align="center"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              {{ scope.row.province + scope.row.city + scope.row.area }}
            </template>
          </el-table-column>
          <el-table-column
            prop="trade"
            label="行业"
            width="80"
            align="center"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="product_type"
            label="产品类型"
            width="120"
            align="center"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="remark"
            label="备注"
            width="120"
            align="center"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="contract_url"
            label="合同文件"
            width="128"
            align="center"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                v-if="scope.row.contract_url"
                @click="handlePreviewContractUrl(scope.row.contract_url)"
                >查看合同文件</el-button
              >
              <span v-else>暂无文件</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="name"
            label="诊断机构"
            width="120"
            align="center"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="contact"
            label="联系人"
            width="70"
            align="center"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="phone"
            label="联系方式"
            width="120"
            align="center"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column label="审批状态" width="120" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.sub_status == 0">平台商审核中</span>
              <span
                v-else-if="scope.row.sub_status == 1 && scope.row.status == 0"
                >政府端审核中</span
              >
              <!-- <span v-if="scope.row.sub_status == 2">平台商已拒绝</span> -->
              <el-popover
                placement="bottom"
                title=""
                width="200"
                trigger="click"
                :content="scope.row.sub_reason"
                v-else-if="scope.row.sub_status == 2"
              >
                <el-button slot="reference" class="filings-update-btn"
                  >平台商已拒绝</el-button
                >
              </el-popover>
              <span v-else-if="scope.row.status == 0">政府审核中</span>
              <span v-else-if="scope.row.status == 1">政府已通过</span>
              <el-popover
                placement="bottom"
                title=""
                width="200"
                trigger="click"
                :content="scope.row.reason"
                v-else-if="scope.row.status == 2"
              >
                <el-button slot="reference" class="filings-update-btn"
                  >政府已拒绝</el-button
                >
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column label="签约状态" width="120" align="center">
            <template slot-scope="scope">
              <span v-html="checktime(scope.row)"></span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="200" align="center">
            <template slot-scope="scope">
              <el-button
                size="mini"
                class="primaryBtn1"
                @click="handleEdit(scope.row.id)"
                v-if="scope.row.status != 1 && scope.row.status != 2"
                >修改</el-button
              >
              <el-button
                size="mini"
                type="danger"
                @click="goDelete(scope.row.id)"
                v-if="scope.row.status != 1"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>

        <div class="publicPage ">
          <el-pagination
            style="text-align: right;"
            background
            @current-change="Changepage"
            :current-page.sync="page"
            :page-size="pageSize"
            layout="prev, pager, next"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  SearchShowCompany,
  Add_edit_list,
  Get_companyApplyList,
  Get_companyApplyInfo,
  // exportDiagnose,
} from "@/api/pts";
import { GetTrade } from "@/api/login";
import { validateMobile } from "@/utils/common.js";
import AreaSelect from "@/components/AreaSelect.vue";
import configs from "@/config/index";
export default {
  components: {
    AreaSelect,
  },
  data() {
    return {
      // 添加/修改备案企业标题
      QYFormTitle: "添加被诊断企业",
      // 表单
      dialogFormVisible: false,
      compylist: [], //搜索列表
      loading: false,
      ruleForm: {
        company_name_temp: "",
        company_name: "",
        code: "",
        zd_qyName: JSON.parse(sessionStorage.getItem("ptsInfo")).name,
        contact: "",
        phone: "",
        product_type: "",
        trade_id: "",
        remark: "",
        contract_url: "",
      },
      areaData: null, //企业地点
      rules: {
        company_name_temp: [
          {
            required: true,
            message: "请选择被诊断企业名称",
            trigger: "change",
          },
        ],
        contact: [
          { required: true, message: "请填写诊断机构联系人", trigger: "blur" },
        ],
        phone: [
          {
            required: true,
            validator: validateMobile.bind(this),
            trigger: "blur",
          },
        ],
        product_type: [
          { required: true, message: "请填写产品类型", trigger: "blur" },
        ],
        trade_id: [
          { required: true, message: "请选择被行业", trigger: "change" },
        ],
        contract_url: [
          { required: true, message: "请上传合同文件", trigger: "change" },
        ],
      },
      addtions: {},
      hylist: JSON.parse(sessionStorage.getItem("hylist")),

      // 表格
      dialogTableVisible: false,
      search: "",
      tableData: [],
      page: 1,
      pageSize: 12,
      total: 0,
      tasks: new Map(),
      uploadUrl: "",
      fileList: [],
    };
  },
  mounted() {
    // this.hylist = JSON.parse(sessionStorage.getItem("hylist"));
    this.getTrade();
    this.uploadUrl = `${configs.baseUrl2}/api/v1/common/upload`;
  },
  methods: {
    handlePreviewContractUrl(contractUrl) {
      let file = JSON.parse(contractUrl)[0];
      let url = file.url;
      let linkDom = document.createElement("a");
      linkDom.href = url;
      linkDom.target = "_blank";
      linkDom.download = file.name;
      linkDom.style.display = "none";
      linkDom.click();
    },
    handleUploadSuccess(response, type) {
      if (response.code == 200) {
        this.fileList = [
          {
            name: response.originalFilename,
            url: response.url,
          },
        ];
        this.ruleForm.contract_url = JSON.stringify(this.fileList);
      } else {
        if (response.msg == "Uploaded file format is limited") {
          this.$message.error("不符合文件上传格式");
        } else {
          this.$message.error(response.msg);
        }
      }
    },
    // handleChange(file, fileList) {
    //   if (file.status == "uploading") {
    //     this.loadingStatus = this.$loading({
    //       lock: true,
    //       text: "上传中...",
    //       spinner: "el-icon-loading",
    //       background: "rgba(0, 0, 0, 0.7)",
    //     });
    //   } else if (file.status == "ready") {
    //     this.loadingStatus = this.$loading({
    //       lock: true,
    //       text: "上传中...",
    //       spinner: "el-icon-loading",
    //       background: "rgba(0, 0, 0, 0.7)",
    //     });
    //   } else {
    //     this.loadingStatus.close();
    //   }
    // },
    handleExceed() {
      this.$message.error("最多可上传1个文件");
    },
    handlePreview(file) {
      let url = file.url;
      let linkDom = document.createElement("a");
      linkDom.href = url;
      linkDom.target = "_blank";
      linkDom.download = file.name;
      linkDom.style.display = "none";
      linkDom.click();
    },
    exportDiagnose() {
      const url =
        configs.baseUrl +
        "/index/company/exportStep?token=" +
        sessionStorage.getItem("token");
      const link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    getTrade() {
      GetTrade().then((res) => {
        this.hylist = res.data;
      });
    },
    handleCloseDialog() {
      this.ruleForm = {};
      this.fileList = [];
    },
    getAreaData(areaObj) {
      this.ruleForm.province = areaObj.provinceId ? areaObj.provinceId : null;
      this.ruleForm.city = areaObj.cityId ? areaObj.cityId : null;
      this.ruleForm.area = areaObj.areaId ? areaObj.areaId : null;
    },

    getDatas(key) {
      console.log(key);
      this.loading = true;
      if (key !== "") {
        let params = {
          name: key,
        };
        SearchShowCompany(params).then((res) => {
          console.log(res);
          setTimeout(() => {
            this.loading = false;
            this.compylist = res.data.data;
          }, 300);
        });
      }
    },
    compychange(val) {
      this.ruleForm.company_name = val.label;
      this.ruleForm.code = val.value;
    },
    showqyform() {
      this.dialogFormVisible = true;
      this.QYFormTitle = "添加被诊断企业";

      this.ruleForm.zd_qyName = JSON.parse(
        sessionStorage.getItem("ptsInfo")
      ).name;
      setTimeout(() => {
        let ptsInfo = JSON.parse(sessionStorage.getItem("ptsInfo"));
        // this.areaData = `${ptsInfo.province}/${ptsInfo.city}/${ptsInfo.area}/`;

        this.areaData = {
          provinceId:
            ptsInfo.province == 0
              ? null
              : typeof ptsInfo.province == "string"
              ? null
              : ptsInfo.province,
          cityId:
            ptsInfo.city == 0
              ? null
              : typeof ptsInfo.city == "string"
              ? null
              : ptsInfo.city,
          areaId:
            ptsInfo.area == 0
              ? null
              : typeof ptsInfo.area == "string"
              ? null
              : ptsInfo.area,
        };
        console.log(this.areaData);
      }, 500);
    },

    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(this.ruleForm);
          delete this.ruleForm.zd_qyName;
          //
          if (this.ruleForm.id) {
            this.sendmessa("/index/Diagnose/editCompanyApply", this.ruleForm);
            this.ruleForm.zd_qyName = JSON.parse(
              sessionStorage.getItem("ptsInfo")
            ).name;
          } else {
            this.sendmessa("/index/Diagnose/addCompanyApply", this.ruleForm);
            this.ruleForm.zd_qyName = JSON.parse(
              sessionStorage.getItem("ptsInfo")
            ).name;
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    sendmessa(url, data) {
      if (data.company_name_temp) {
        delete data.company_name_temp;
      }
      if (data.id) {
        delete data.add_id;
        delete data.check_time;
        delete data.hy;
        delete data.reason;
        delete data.sub_reason;
        delete data.apply_user;
        delete data.user_id;
      }
      Add_edit_list(url, data)
        .then((res) => {
          if (res.code == 1) {
            console.log(res);
            this.$alert(
              "<div >平台商录入企业成功</div>",
              // "<div >平台商录入企业成功，并在政府端审核通过后，系统将自动生成一-条四化诊断记录，同时为该企业创建一账号及密码，账号为企业机构代码，初始密码为: @qy_2023.</div>",
              "提醒",
              {
                confirmButtonText: "确定",
                dangerouslyUseHTMLString: true,
                showClose: false,
              }
            ).then(() => {
              this.ruleForm = {};
              this.dialogFormVisible = false;
              if (url == "/index/Diagnose/editCompanyApply") {
                this.Search_Table_list();
              }
            });
          }
        })
        .finally(() => {
          // this.Search_Table_list();
        });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.dialogFormVisible = false;
    },
    handleAreaChange(value) {
      this.ruleForm.province = value.province;
      this.ruleForm.city = value.city;
      this.ruleForm.area = value.area;
    },
    // 表格
    showTable_list() {
      this.Search_Table_list();
    },
    Search_Table_list() {
      let params = {
        search: this.search,
        page: this.page,
      };
      Get_companyApplyList(params).then((res) => {
        this.tableData = res.data;
        this.total = res.data.total;
        this.dialogTableVisible = true;
        console.log(res, this.total);

        // 定时计时任务，这里是1秒执行一次
        setInterval(function() {
          for (var key in this.tasks) {
            this.tasks[key]();
          }
        }, 1000);
      });
    },
    checktime(a) {
      console.log(a);
      // 在提交时间上 延长7天, 再把 毫秒 转为 秒

      if (a.status == 1 && a.step == 1) {
        var endTime = Math.floor(
          (a.check_time * 1000 + 7 * 24 * 60 * 60 * 1000) / 1000
        );
        // var endTime = Math.floor((a.check_time*1000 + 2 * 60 * 1000) / 1000);
        //   var endTime = Math.floor(a.check_time);
        // 计算 剩下的秒，即留给审批的时间
        var t = endTime - Math.floor(new Date().getTime() / 1000);
        //   var t = endTime;
        // 设置每一条数据唯一的key
        var key = "key_" + a.id;
        // 判断是否逾期
        if (t <= 0) {
          //已逾期，直接返回，提示已逾期
          return `失效`;
        }
        //未逾期 > 开始处理剩余时间，将秒转为n日n时n分n秒的形式，代码在下方88-108行
        var left_time = this.secondToStr(t);
        var html = `<label id=${key} style="">${left_time}</label>`;
        this.addTask(key, function() {
          t--;
          var left = this.secondToStr(t);

          if (t == 0) {
            //倒计时结束，删除定时任务
            this.delTask(key);
            // $('#' + key).text("失效");
            return "失效";
            // 可根据情况，进行逾期之后的操作
            //...........
          } else {
            return left;
          }
          // $('#' + key).text(left);
        });
        return html;
      } else if (a.status == 1 && (a.step == 2 || a.step == 3)) {
        return "正常生效";
      } else {
        return "";
      }
    },
    // 添加定时任务
    addTask(key, value) {
      if (typeof value === "function") {
        this.tasks[key] = value;
      }
    },
    // 删除定时任务
    delTask(task) {
      delete this.tasks[task];
    },
    // 剩余秒 转化为 剩余日时分秒
    secondToStr(t) {
      // console.log(t)
      //日
      var left_day = parseInt(t / (60 * 60 * 24));
      // console.log(left_day)
      //时
      var left_hour = parseInt((t % (60 * 60 * 24)) / (60 * 60));
      if (left_hour < 10) {
        left_hour = "0" + left_hour;
      }
      //分
      var left_minute = parseInt((t % 3600) / 60);
      if (left_minute < 10) {
        left_minute = "0" + left_minute;
      }
      //秒
      var left_second = t % 60;
      if (left_second < 10) {
        left_second = "0" + (t % 60);
      }
      //   return left_day+"天 "+left_hour+"时 "+left_minute+"分 "+left_second+"秒";
      return left_day + "天 " + left_hour + "时 ";
    },
    // 编辑表格信息
    handleEdit(id) {
      console.log(id);
      Get_companyApplyInfo({ id: id })
        .then((res) => {
          console.log(res);
          this.dialogFormVisible = true;
          this.QYFormTitle = "修改被诊断企业";
          this.$nextTick(() => {
            this.ruleForm = res.data;
            this.ruleForm.company_name_temp = res.data.company_name;
            this.ruleForm.contract_url = JSON.parse(res.data.contract_url);
            this.ruleForm.trade_id = Number(res.data.trade_id);
            this.ruleForm.zd_qyName = JSON.parse(
              sessionStorage.getItem("ptsInfo")
            ).name;
            // this.ruleForm.trade_id = this.ruleForm.trade_id.toString();
            // this.areaData = `${this.ruleForm.province}/${this.ruleForm.city}/${this.ruleForm.area}/`;
            this.areaData = {
              provinceId:
                this.ruleForm.province == 0 ? null : this.ruleForm.province,
              cityId: this.ruleForm.city == 0 ? null : this.ruleForm.city,
              areaId: this.ruleForm.area == 0 ? null : this.ruleForm.area,
            };
          });
        })
        .finally(() => {
          // this.Search_Table_list();
        });
    },
    Changepage(val) {
      console.log(val);
      this.page = val;
      this.Search_Table_list();
    },
    hidepa() {
      console.log(111);
      this.page = 1;
      this.dialogTableVisible = false;
    },
    async goDelete(id) {
      console.log(id);

      if (
        await this.$Delete("post", "/index/Diagnose/companyApplyDel", {
          id: id,
        })
      ) {
        this.Search_Table_list();
      }
    },
  },
};
</script>
<style lang="less" scoped>
.qiyeba {
  .tablelist {
    .filings-update-btn {
      background-color: transparent;
      border-color: transparent;
      color: #205dc3;
      cursor: pointer;
    }

    .el-pagination {
      width: 100%;
    }
  }
}
</style>
