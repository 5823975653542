<template>
  <div class="main">
    <div class="container">
      <el-breadcrumb separator=">">
        <el-breadcrumb-item
          :to="{
            path: '/government/sbzj',
          }"
          >申报征集</el-breadcrumb-item
        >
        <el-breadcrumb-item> 试点意向企业入库申报</el-breadcrumb-item>
      </el-breadcrumb>
      <el-tabs
        style="margin-top: 20px;"
        v-model="verifyType"
        @tab-click="handleChangeVerifyType"
      >
        <el-tab-pane label="已审批企业" name="yspqy"> </el-tab-pane>
        <el-tab-pane label="待审批企业" name="dspqy"> </el-tab-pane>
      </el-tabs>

      <div class="verify-form">
        <el-form :inline="true" :model="verifyForm_qy">
          <el-form-item label="公司名称">
            <el-input
              v-model="verifyForm_qy.enterpriseName"
              placeholder="请输入公司名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="行业">
            <el-select
              v-model="verifyForm_qy.subIndustry"
              placeholder="请选择行业"
              clearable
              style="width: 150px;"
            >
              <!-- <el-option
                v-for="item in hyList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option> -->
              <el-option
                v-for="item in hyList"
                :key="item.id"
                :label="item.name"
                :value="item.name"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="区域">
            <el-select
              v-model="verifyForm_qy.administrativeDistrict"
              placeholder="请选择区域"
              clearable
              style="width: 120px;"
            >
              <!-- <el-option
                v-for="item in areaList"
                :key="item.id"
                :label="item.name_cn"
                :value="item.id"
              ></el-option> -->
              <el-option
                v-for="item in areaList"
                :key="item.id"
                :label="item.name_cn"
                :value="item.name_cn"
              ></el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="审批状态">
            <el-select
              v-model="verifyForm_qy.status"
              placeholder="请选择审批状态"
              clearable
              style="width: 150px;"
            >
              <el-option
                v-for="(item, index) in statusList"
                :key="index"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item label="数字化水平">
            <el-input
              v-model="verifyForm_qy.assessLv"
              placeholder="请输入数字化水平"
            ></el-input>
          </el-form-item>
          <el-form-item label="意向牵引单位">
            <el-input
              v-model="verifyForm_qy.company"
              placeholder="请输入意向牵引单位"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              class="primaryBtn1"
              type="primary"
              @click="handleSubmit_qy"
              >查询</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <div class="verify-table">
        <template>
          <el-table
            ref="multipleTable"
            :data="tableData_qy"
            border
            style="width: 100%"
            @selection-change="handleQYSelectionChange"
            :key="itemKey_qy"
          >
            <el-table-column type="selection" width="55" key="qy_select">
            </el-table-column>
            <el-table-column type="index" label="序号" width="55" key="qy_序号">
            </el-table-column>
            <el-table-column
              prop="enterpriseName"
              label="公司名称"
              show-overflow-tooltip
              key="qy_公司名称"
            >
            </el-table-column>
            <el-table-column
              prop="socialCreditCode"
              label="机构代码"
              show-overflow-tooltip
              key="qy_机构代码"
            >
            </el-table-column>
            <el-table-column
              prop="administrativeDistrict"
              label="行政区域"
              show-overflow-tooltip
              key="qy_行政区域"
            >
              <!-- <template slot-scope="scope">
                <span>
                  {{
                    scope.row.province +
                      " " +
                      scope.row.city +
                      " " +
                      scope.row.area
                  }}
                </span>
              </template> -->
            </el-table-column>
            <el-table-column
              prop="subIndustry"
              label="行业"
              show-overflow-tooltip
              key="qy_行业"
            >
            </el-table-column>
            <el-table-column
              prop="assessLv"
              label="数字化水平等级"
              show-overflow-tooltip
              key="qy_数字化水平等级"
            >
            </el-table-column>
            <el-table-column
              prop="govementSelect"
              label="意向牵引单位"
              show-overflow-tooltip
              key="qy_意向牵引单位"
            >
            </el-table-column>
            <el-table-column
              prop="enterpriseCategoryOne"
              label="专精特新企业类型"
              show-overflow-tooltip
              key="qy_专精特新企业类型"
            >
            </el-table-column>
            <el-table-column
              prop="isSelected"
              label="是否需要对接"
              show-overflow-tooltip
              key="qy_是否需要对接"
            >
            </el-table-column>
            <!-- <el-table-column
              prop="product_type"
              label="产品类型"
              show-overflow-tooltip
              key="qy_产品类型"
            >
            </el-table-column>
            <el-table-column
              prop="remark"
              label="备注"
              show-overflow-tooltip
              key="qy_备注"
            >
            </el-table-column>
            <el-table-column
              prop="name"
              label="诊断机构"
              show-overflow-tooltip
              key="qy_诊断机构"
            >
            </el-table-column> -->
            <el-table-column
              prop="contactPerson"
              label="联系人"
              show-overflow-tooltip
              key="qy_联系人"
            >
            </el-table-column>
            <el-table-column
              prop="phone"
              label="联系方式"
              show-overflow-tooltip
              key="qy_联系方式"
            >
            </el-table-column>
            <el-table-column
              prop="auditStatus"
              label="审批状态"
              show-overflow-tooltip
              key="qy_审批状态"
            >
              <template slot-scope="scope">
                <span v-if="scope.row.auditStatus == 1">审批中</span>
                <span v-if="scope.row.auditStatus == 2">已通过</span>
                <span v-if="scope.row.auditStatus == 3">已拒绝</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" key="qy_操作">
              <template slot-scope="scope">
                <el-button type="text" @click="handleQYDetail(scope.row)"
                  >详情</el-button
                >
                <el-button
                  type="text"
                  @click="handleQYPassAndReject(scope.row, 'pass')"
                  v-if="scope.row.auditStatus == 1"
                  >通过</el-button
                >
                <el-button
                  type="text"
                  @click="handleQYPassAndReject(scope.row, 'reject')"
                  v-if="scope.row.auditStatus == 1"
                  >拒绝</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </template>
      </div>
      <div class="verify-pager flex bewteen vertical">
        <div style="width: 450px;margin: 10px 0;">
          <el-button
            v-if="verifyType == 'dspqy'"
            type="primary"
            @click="showVerifyFormMoreDialog('pass')"
            >批量通过</el-button
          >
          <el-button
            v-if="verifyType == 'dspqy'"
            type="danger"
            @click="showVerifyFormMoreDialog('reject')"
            >批量拒绝</el-button
          >
          <el-button type="primary" @click="doExportCompanyDigitalizeFile()"
            >批量导出</el-button
          >
        </div>
        <el-pagination
          background
          layout="prev, pager, next"
          :total="total_qy"
          :current-page="page_qy"
          :page-size="pageSize"
          @current-change="handleCurrentChange_qy"
        >
        </el-pagination>
      </div>
    </div>

    <!-- 弹窗开始   -->
    <el-dialog
      title="审核"
      :visible.sync="verifyFormDialog"
      width="500px"
      :before-close="handleCloseDialog"
      :close-on-click-modal="false"
      class="verifyForm"
    >
      <el-form :inline="true" :model="verifyForm" style="width: 100%;">
        <el-form-item>
          <el-input
            type="textarea"
            maxlength="150"
            rows="6"
            show-word-limit
            v-model="verifyForm.remark"
            placeholder="请输入原因"
          ></el-input>
        </el-form-item>
        <el-form-item v-if="verifyFormType == 'pass'">
          <el-select
            v-model="importCompanyName"
            filterable
            multiple
            placeholder="请选择意向数字化牵引单位"
            style="width: 68%;"
          >
            <el-option
              v-for="(item, index) in importCompanyList"
              :key="index"
              :label="item.label"
              :value="item.label"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCloseDialog">取 消</el-button>
        <el-button type="primary" @click="doQYReject">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 弹窗结束 -->
    <!-- 弹窗开始   -->
    <el-dialog
      title="批量审核"
      :visible.sync="verifyFormMoreDialog"
      width="500px"
      :before-close="handleCloseDialog"
      :close-on-click-modal="false"
      class="verifyForm"
    >
      <el-form :inline="true" :model="verifyForm" style="width: 100%;">
        <el-form-item>
          <el-input
            type="textarea"
            maxlength="150"
            rows="6"
            show-word-limit
            v-model="verifyForm.remark"
            placeholder="请输入原因"
          ></el-input>
        </el-form-item>
        <el-form-item v-if="verifyFormType == 'pass'">
          <el-select
            v-model="importCompanyName"
            filterable
            multiple
            placeholder="请选择意向数字化牵引单位"
            style="width: 68%;"
          >
            <el-option
              v-for="(item, index) in importCompanyList"
              :key="index"
              :label="item.label"
              :value="item.label"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCloseDialog">取 消</el-button>
        <el-button type="primary" @click="idsVerify">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 弹窗结束 -->
  </div>
</template>
<script>
// import configs from "@/config/index";
import { formatDateTime } from "@/utils/common";
import { getTractionUnit } from "@/api/pts";
import {
  getCompanyDigitalizeObscureList,
  govementUpdateCompany,
  governmentBatchCheck,
  exportCompanyDigitalizeFile,
} from "@/api/zf";
import { getArea } from "@/api/login";
export default {
  name: "ApprovalCenter",
  data() {
    return {
      verifyType: "yspqy",

      // 行业列表
      hyList: [
        {
          id: 1,
          name: "智能网联和新能源汽车（零部件）",
        },
        {
          id: 2,
          name: "工业母机和机器人",
        },
        {
          id: 3,
          name: "时尚美妆",
        },
        {
          id: 4,
          name: "定制家居",
        },
        {
          id: 5,
          name: "服装",
        },
        {
          id: 6,
          name: "箱包",
        },
      ],
      // 区域列表
      areaList: [],
      // 审核状态列表
      // statusList: ["待审核", "审核通过", "审核拒绝"],
      statusList: [
        { label: "待审核", value: 1 },
        { label: "审核通过", value: 2 },
        { label: "审核拒绝", value: 3 },
      ],

      // 搜索已审批企业
      verifyForm_qy: {
        enterpriseName: null,
        subIndustry: null,
        administrativeDistrict: null,
        assessLv: null,
        company: null,
        auditStatus: 2,
      },
      itemKey_qy: "",
      // 已审批企业表格
      tableData_qy: [],
      total_qy: 0,
      page_qy: 1,

      pageSize: 10,

      verifyForm: {
        remark: "",
      },
      verifyRow: {},
      verifyFormDialog: false,
      verifyFormType: "",
      // 企业下拉框
      importCompanyList: [
        // {
        //   id: 1,
        //   name: "广州明珞装备股份有限公司",
        // },
        // {
        //   id: 2,
        //   name: "格创东智（广州）科技技术有限公司",
        // },
        // {
        //   id: 3,
        //   name: "浪潮云洲工业互联网有限公司",
        // },
        // {
        //   id: 4,
        //   name: "广州数控设备有限公司",
        // },
        // {
        //   id: 5,
        //   name: "树根互联股份有限公司",
        // },
        // {
        //   id: 6,
        //   name: "广东玛斯特智能系统有限公司",
        // },
        // {
        //   id: 7,
        //   name: "广州蜂巢互联科技有限公司",
        // },
        // {
        //   id: 8,
        //   name: "广州中浩控制技术有限公司",
        // },
        // {
        //   id: 9,
        //   name: "广州环亚化妆品科技股份有限公司",
        // },
        // {
        //   id: 10,
        //   name: "广州尚品宅配家居股份有限公司",
        // },
        // {
        //   id: 11,
        //   name: "欧派家居集团股份有限公司",
        // },
        // {
        //   id: 12,
        //   name: "广州鼎捷软件有限公司",
        // },
        // {
        //   id: 13,
        //   name: "比音勒芬服饰股份有限公司",
        // },
        // {
        //   id: 14,
        //   name: "广东省纺织品进出口股份有限公司",
        // },
        // {
        //   id: 15,
        //   name: "广州春晓信息科技有限公司",
        // },
        // {
        //   id: 16,
        //   name: "广州盖特软件有限公司",
        // },
        // {
        //   id: 17,
        //   name: "联通(广东)产业互联网有限公司",
        // },
        // {
        //   id: 18,
        //   name: "杭州海康威视数字技术股份有限公司",
        // },
        // {
        //   id: 19,
        //   name: "华为云计算技术有限公司",
        // },
        // {
        //   id: 20,
        //   name: "广东用友软件有限公司",
        // },
        // {
        //   id: 21,
        //   name: "金蝶软件(中国)有限公司",
        // },
        // {
        //   id: 22,
        //   name: "广东丸美生物技术股份有限公司",
        // },
        // {
        //   id: 23,
        //   name: "广州赛意信息科技股份有限公司",
        // },
        // {
        //   id: 24,
        //   name: "广东粤桨产业科技有限公司",
        // },
      ],
      importCompanyName: [],

      //批量审核
      verifyFormMoreDialog: false,
      // 多选的id列表
      ids: [],
      // 多选的id列表状态
      idsStatus: [],

      // 是否正在进行审核操作
      isVerifing: false,
    };
  },
  created() {
    this.getVerifyList_qy();
    // this.hyList = JSON.parse(sessionStorage.getItem("hylist"));
    // this.areaList = configs.areaOptions;
    this.getGUANGZHOUAreaList();
    this.getTractionUnit();
  },
  mounted() {
    this.$nextTick(() => {});
  },
  methods: {
    getTractionUnit() {
      getTractionUnit(1).then((res) => {
        console.log("res getTractionUnit", res);
        if (res.code == 200) {
          this.importCompanyList = res.data.selectRespList;
        }
      });
    },
    doExportCompanyDigitalizeFile() {
      let _this = this;

      this.$confirm("是否批量导出?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let data = {
            // type: 1,
            ids: JSON.stringify(_this.ids),
          };
          if (_this.ids.length == 0) {
            // data.type = 2;
            delete data.ids;
          }
          exportCompanyDigitalizeFile(data)
            .then((res) => {
              console.log(res, "------------");
              if (res) {
                const fileName = `试点意向企业入库申报-${new Date().getTime()}.xlsx`;
                const blob = new Blob([res]);
                // 获取heads中的filename文件名
                const downloadElement = document.createElement("a");
                // 创建下载的链接
                const href = window.URL.createObjectURL(blob);
                downloadElement.href = href;
                // 下载后文件名
                downloadElement.download = fileName;
                document.body.appendChild(downloadElement);
                // 点击下载
                downloadElement.click();
                // 下载完成移除元素
                document.body.removeChild(downloadElement);
                // 释放掉blob对象
                window.URL.revokeObjectURL(href);
              }
            })
            .catch((err) => {
              console.log(err);

              this.$message({
                type: "error",
                message: "导出失败",
              });
            });
        })
        .catch(() => {
          _this.isVerifing = false;
          _this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },

    showVerifyFormMoreDialog(type) {
      if (this.ids.length == 0) {
        this.$message.error("请选择要审核的数据");
        return false;
      }
      let _this = this;
      if (
        _this.idsStatus.indexOf(2) != -1 ||
        _this.idsStatus.indexOf(3) != -1
      ) {
        _this.$message.error("不可审核已通过/已拒绝的审批");
        return false;
      }
      let title = "是否批量通过审批?";
      if (type == "reject") {
        title = "是否批量拒绝审批?";
      }
      this.$confirm(title, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.verifyFormMoreDialog = true;
          this.verifyFormType = type;
          console.log(this.verifyFormType, "---=====showVerifyFormMoreDialog");
        })
        .catch(() => {
          _this.isVerifing = false;
          _this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    idsVerify() {
      let _this = this;
      if (this.isVerifing) {
        return false;
      }
      this.isVerifing = true;
      console.log(123);

      let userId = [];
      let importCompanyListTemp = [];
      _this.importCompanyName.forEach((vitem) => {
        if (importCompanyListTemp.indexOf(vitem) == -1) {
          importCompanyListTemp.push(vitem);
          _this.importCompanyList.forEach((item) => {
            if (item.label == vitem) {
              userId.push(item.value);
            }
          });
        }
      });

      let pdata = {
        ids: _this.ids.join(","),
        // ids: JSON.stringify(_this.ids),
        auditUserId: JSON.parse(sessionStorage.getItem("user")).id, //审核人id
        auditReason: _this.verifyForm.remark,
        auditStatus: 1,
        govementSelect: _this.importCompanyName.join(","),
        govementUserId: userId.join(","),
      };
      if (_this.verifyFormType == "pass") {
        pdata.auditStatus = 2;
      } else if (_this.verifyFormType == "reject") {
        pdata.auditStatus = 3;
      }
      // governmentBatchCheck(pdata)
      govementUpdateCompany(pdata) //现在批量跟单个用的是同一个接口
        .then((res) => {
          console.log("res", res);

          _this.$message({
            type: "success",
            message: "操作成功",
          });
        })
        .catch((err) => {
          console.log("error!", err);

          _this.$message({
            type: "error",
            message: "操作失败",
          });
        })
        .finally(() => {
          _this.handleSubmit_qy();
          _this.isVerifing = false;
          _this.handleCloseDialog();
        });
    },
    handleQYSelectionChange(items) {
      console.log("items----------->", items);

      this.ids = items.map((item) => {
        return item.id;
      });
      this.idsStatus = items.map((item) => {
        return item.auditStatus;
      });
    },

    doQYReject() {
      let _this = this;
      // 如果还在进行审核操作，return
      if (_this.isVerifing) {
        return false;
      }
      _this.isVerifing = true;

      let userId = [];
      let importCompanyListTemp = [];
      _this.importCompanyName.forEach((vitem) => {
        if (importCompanyListTemp.indexOf(vitem) == -1) {
          importCompanyListTemp.push(vitem);
          _this.importCompanyList.forEach((item) => {
            if (item.label == vitem) {
              userId.push(item.value);
            }
          });
        }
      });

      let data = {
        auditStatus: 1, //审核状态 1：待审核 2：审核通过 3：审核不通过
        auditReason: _this.verifyForm.remark, //审核不通过原因
        auditUserId: JSON.parse(sessionStorage.getItem("user")).id, //审核人id
        govementSelect: _this.importCompanyName.join(","),
        govementUserId: userId.join(","),
      };
      if (this.verifyFormType == "pass") {
        data.auditStatus = 2;
      } else if (this.verifyFormType == "reject") {
        data.auditStatus = 3;
      }

      govementUpdateCompany({
        ids: this.verifyRow.id + "", //该条产品申报id
        // companyDigitalizeAgreeSelect: JSON.stringify(data),
        ...data,
      })
        .then((res) => {
          // console.log(res);
          if (res.code == 200) {
            this.$message.success("操作成功");
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log("error!", err);
          _this.$message({
            type: "error",
            message: "操作失败",
          });
        })
        .finally(() => {
          _this.handleCloseDialog();
          _this.handleSubmit_qy();
          _this.isVerifing = false;
        });
    },
    handleCloseDialog() {
      this.verifyFormDialog = false;
      this.verifyForm = {};
      this.verifyRow = {};
      this.verifyFormType = "";
      this.importCompanyName = [];
      this.ids = [];
      this.idsStatus = [];

      this.verifyFormMoreDialog = false;
      this.$refs.multipleTable.clearSelection();
    },
    handleQYPassAndReject(row, type) {
      if (row.auditStatus != 1) {
        this.$message.error("该审核状态不可更改");
        return false;
      }
      // console.log("row---===", row);
      this.verifyRow = row;
      this.verifyFormType = type;
      // this.importCompanyName = row.govementSelect;
      this.verifyFormDialog = true;
    },

    //跳转问卷详情
    handleQYDetail(row) {
      this.$router.push({
        path: "/government/sbzj/formSecond",
        query: {
          type: 2,
          id: row.id,
        },
      });
    },

    //获取table数据
    getVerifyList_qy() {
      // let pdata = {
      //   req: JSON.stringify(this.verifyForm_qy),
      //   // auditStatus: 1,
      //   page: this.page_qy,
      // };
      let pdata = {
        pageNum: this.page_qy,
        pageSize: this.pageSize,
        ...this.verifyForm_qy,
      };
      getCompanyDigitalizeObscureList(pdata).then((res) => {
        console.log("res", res);
        this.tableData_qy = res.data.list;
        this.total_qy = res.data.total;
        this.itemKey_qy = Math.random() + "_qy";
      });
    },
    getGUANGZHOUAreaList() {
      let pdata = {
        pid: 236,
      };

      getArea(pdata).then((res) => {
        console.log("res", res);

        this.areaList = res.data;
      });
    },
    // 分页器pagechange
    handleCurrentChange_qy(page) {
      this.page_qy = page;
      if (this.verifyType == "yspqy") {
        this.verifyForm_qy.auditStatus = 2;
        this.getVerifyList_qy();
      } else if (this.verifyType == "dspqy") {
        this.verifyForm_qy.auditStatus = 1;
        this.getVerifyList_qy();
      }
    },
    // 查询
    handleSubmit_qy() {
      if (this.verifyType == "yspqy") {
        this.verifyForm_qy.auditStatus = 2;
        this.getVerifyList_qy();
      } else if (this.verifyType == "dspqy") {
        this.verifyForm_qy.auditStatus = 1;
        this.getVerifyList_qy();
      }
    },
    formatDateTime(timetemp) {
      return formatDateTime(timetemp);
    },
    handleChangeVerifyType(obj) {
      // 每次切换都发一次请求 相当于刷新
      this.page_qy = 1;
      this.tableData_qy = [];
      this.verifyForm_qy = {};
      if (obj.name == "yspqy") {
        this.verifyForm_qy.auditStatus = 2;
        this.getVerifyList_qy();
      } else {
        this.verifyForm_qy.auditStatus = 1;
        this.getVerifyList_qy();
      }
      this.verifyType = obj.name;
    },
  },
};
</script>
<style lang="less" scoped>
::v-deep .el-pagination {
  //width: 100%;
  margin: 10px auto;
  text-align: right;
  box-sizing: border-box;
}
::v-deep .el-table td.el-table__cell div {
  text-align: center;
}
::v-deep .el-tabs__item {
  color: #666;
  font-size: 22px;
  margin-bottom: 8px;
  &.is-active {
    color: #333;
    font-weight: 600;
  }
  &:not(.is-active):hover {
    color: #666;
  }
}
::v-deep .el-tabs__active-bar {
  height: 3px;
  // margin-top: 8px;
}
::v-deep .el-tabs__nav-wrap::after {
  height: 1px;
  background-color: #d8d8d8;
}
::v-deep .el-form-item {
  margin-bottom: 10px;
}
::v-deep .el-table th.el-table__cell > .cell {
  text-align: center;
}
::v-deep .el-table th.el-table__cell.is-leaf {
  color: #333;
  background-color: #eef2ff;
  border-color: #d8d8d8;
}
::v-deep .el-table--border {
  border-color: #d8d8d8;
}
.main {
  .container {
    width: 84%;
    margin: 0 auto;
    padding: 30px 25px 0;
    background-color: #fff;
    border-radius: 10px;
    box-sizing: border-box;
    overflow: hidden;
  }
}
.approval-main {
  border-left: 2px solid #205dc3;
  margin: 20px 50px;

  .approval-list {
    padding-left: 50px;
    .approval-item {
      .approval-title {
        position: relative;

        color: #666;
        font-size: 16px;
        line-height: 16px;
        margin-bottom: 20px;
        margin-top: 20px;

        &::before {
          content: "";
          width: 16px;
          height: 16px;
          border-radius: 18px;
          background-color: #205dc3;

          position: absolute;
          left: -59px;
          top: 0;
          bottom: 0;
          margin: auto 0;
        }
      }
      .approval-body {
        margin-bottom: 10px;
        font-size: 16px;
        line-height: 32px;
        display: flex;
        align-items: flex-start;
        .body-status {
          display: inline-block;
          // width: 150px;
          line-height: 1;
          padding: 3px 8px;
          text-align: center;
          border-radius: 3px;
          font-size: 12px;
          margin: 6px 0 0 15px;
          &.wait {
            color: #666;
            border: 1px solid #666;
          }
          &.pass {
            color: #67c23a;
            border: 1px solid #67c23a;
          }
          &.reject {
            color: #f56c6c;
            border: 1px solid #f56c6c;
          }
        }
        .body-time {
          margin-left: 15px;
          font-size: 12px;
          color: #666;
        }
        .body-person {
          display: inline-block;
          height: fit-content;
          text-align: left;
          color: #205dc3;
          border-radius: 3px;
          line-height: 2;
          max-width: 54%;
          &.no {
            cursor: default;
            color: #999;
          }
        }

        .choose-btn {
          margin-left: 15px;
        }
      }

      &:last-child {
        .approval-title {
          margin-bottom: 0;
        }
      }
    }
  }
}

.content-body {
  // padding: 25px 30px;
  overflow-x: auto;
  .content-body-item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;
    .content-body-content {
      width: calc(100% - 65px);
      .content-html {
        img {
          width: 100% !important;
        }
      }
      img {
        max-width: 100%;
        width: fit-content;
        border-radius: 5px;
      }
    }
    .content-body-title {
      width: 65px;
      color: #666666;
    }
  }
}
::v-deep .verifyForm {
  .rejectTips {
    font-size: 18px;
    padding: 10px 0;
  }
  .el-dialog__body {
    padding: 10px 25px;
  }
  .el-form--inline .el-form-item__content,
  .el-form--inline .el-form-item {
    width: 100%;
  }
}
</style>
