<template>
  <div class="container">
    <div class="same-container">
      <div class="size-container">
        <div class="public-container">
          <div class="top-nav">
            <el-breadcrumb separator=">">
              <el-breadcrumb-item
                :to="{
                  path: '/enterprise/sbzj',
                }"
                >申报征集</el-breadcrumb-item
              >
              <el-breadcrumb-item>试点意向企业入库申报</el-breadcrumb-item>
            </el-breadcrumb>
          </div>

          <div class="list-content">
            <div
              class="list-item"
              v-for="(item, index) in dataList"
              :key="index"
              :style="$bgImg('bg_paper.png')"
            >
              <div class="item-left">
                <div class="title">
                  <span>{{ item.enterpriseName }} </span>
                </div>
                <div class="auditStatus">
                  <span v-if="item.auditStatus == 1" style="color: orange;"
                    >待审核</span
                  >
                  <span v-else-if="item.auditStatus == 2" style="color: green;"
                    >审核通过</span
                  >
                  <span v-else-if="item.auditStatus == 3" style="color: red;"
                    >审核不通过</span
                  >
                </div>
                <div class="time">
                  <img class="time-icon" :src="'time.png' | staticMedia" />
                  <span>{{ item.updatetime }}</span>
                </div>
              </div>
              <div class="item-right">
                <!-- <el-button
                  type="primary"
                  class="pri-btn"
                  @click="goApplication(item, 1)"
                >
                  申报
                </el-button> -->
                <el-button
                  type="primary"
                  @click="goApplication(item, 2)"
                  class="cancel-btn"
                >
                  查看
                </el-button>
              </div>
            </div>
          </div>

          <!-- 分页 -->
          <el-pagination
            class="pagination"
            :total="total"
            :current-page="page"
            :page-size="+pageSize"
            :layout="'prev, pager, next'"
            @current-change="handlePageChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getmyCompanyDigitalizeList } from "@/api/qy";
import { formatDateTime } from "@/utils/common";
export default {
  data() {
    return {
      dataList: [],

      // 分页
      total: 0,
      page: 1,
      currentPage: 1,
      pageSize: 10, // 默认10个
    };
  },
  created() {
    this.getList();
  },

  computed: {},
  methods: {
    handlePageChange(val) {
      this.page = val;
      this.getList();
    },
    getList() {
      getmyCompanyDigitalizeList({
        id: JSON.parse(sessionStorage.getItem("user")).id,
        pageNum: this.page,
        pageSize: +this.pageSize,
      }).then((res) => {
        if (res.code === 200) {
          console.log("this.datalist", res);
          res.data.list.forEach((item) => {
            item.updatetime = formatDateTime(item.updatetime);
          });
          this.dataList = res.data.list;
          this.total = res.data.total;
          // this.pageSize = res.data.per_page;
          // this.currentPage = +res.data.current_page;
        }
      });
    },
    gotoIndex() {
      this.$router.push({
        path: "/enterprise/sbzj",
      });
    },
    goApplication(item, type) {
      this.$router.push({
        name: "enterpriseSbzjForm",
        // type = 1为申报
        // type = 2为查看
        query: {
          type,
          id: item.id,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background-color: #eff4fe;

  .same-container {
    width: 100%;
    height: 100%;
    background: #f0f5ff;
    background-size: 1920px 1000px;
    background-attachment: fixed;

    .size-container {
      width: 82%;
      height: 100%;
      margin: 0 auto;
      min-height: 700px;

      .public-container {
        overflow: hidden;
        position: relative;
        width: 100%;
        min-width: 1200px;
        margin: auto;
        padding: 10px;
        box-sizing: border-box;
        display: flex;
        flex-wrap: wrap;

        .top-nav {
          margin-bottom: 30px;
        }

        .list-content {
          width: 100%;
          height: 100%;
          // padding-top: 30px;

          .list-item {
            width: 100%;
            height: 150px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;

            padding: 40px 40px 30px;
            box-sizing: border-box;
            background: #ffffff;
            background-position: 86% 150px;
            background-repeat: no-repeat;
            background-size: auto 130%;
            border-radius: 10px;
            margin-bottom: 20px;
            transition: all 0.5s;

            &:hover {
              background: #f3f7ff;
              background-position: 86% -25px;
              background-repeat: no-repeat;
              background-size: auto 130%;
              box-shadow: 0 0 10px #205dc375;

              .item-left {
                .title {
                  color: #2282f9;
                }
              }
            }

            .item-left {
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: space-between;

              .title {
                font-size: 20px;
                font-weight: 700;
                font-family: Microsoft YaHei;
                color: #333333;
                cursor: default;
                transition: all 0.5s;
              }
              .auditStatus {
                margin: 20px 0;

                font-size: 16px;
                font-weight: 500;
                font-family: Microsoft YaHei;
                color: #333333;
                cursor: default;
              }
              .time {
                font-family: Microsoft YaHei;
                font-size: 14px;
                font-weight: normal;
                line-height: 26px;
                letter-spacing: 0em;
                color: #333333;
                cursor: default;

                display: flex;
                align-items: center;

                .time-icon {
                  width: 25px;
                  height: 25px;

                  margin-right: 5px;
                }
              }
            }

            .item-right {
            }
          }
        }
      }
    }
  }
}
</style>
