<template>
  <div id="app">
    <!--留坑，非常重要-->

    <router-view name="header"></router-view>

    <head-nav v-if="$route.meta.keepAlive"></head-nav>

    <transition name="el-fade-in">
      <router-view class="Main"></router-view>

      <!--name省略表示name='default'-->
    </transition>

    <router-view name="footer"></router-view>
  </div>
</template>

<script>
import HeadNav from "@/components/MeanHeader";
// import { Boot } from '@wangeditor/editor';
// import attachmentModule from '@wangeditor/plugin-upload-attachment';
export default {
  name: "App",
  components: { HeadNav },
  mounted() {
    // Boot.registerModule(attachmentModule)
  },
};
</script>

<style lang="less">
body {
  margin: 0;
  padding: 0;
  // overflow-y:scroll;
  scrollbar-base-color: transparent;
  scrollbar-darkshadow-color: transparent;
  scrollbar-highlight-color: transparent;
  scrollbar-face-color: transparent;
  color: #333;
}

.el-loading-mask {
  z-index: 10000 !important;
}

.Main {
  min-height: calc(100vh - 408px);
  padding: 30px 0;
  box-sizing: border-box;
  min-width: 1200px;
}

.main {
  background: #f0f5ff;
  background-size: cover;
  background-attachment: fixed;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  will-change: transform;
  transition: all 500ms;
  position: absolute;
}

.slide-fade-enter {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}

.slide-fade-leave-active {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}

//解决空白的问题就是下面这段css
.page {
  //  position: absolute;
  //  top: 192px;
  //  bottom: 0;
  width: 100%;
  margin: 0 auto;
}

//测试提交
</style>
