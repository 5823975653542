<template>
  <div class="container">
    <div class="same-container">
      <div class="size-container">
        <div
          :class="
            navName === '名单公示' ? 'public-container' : 'list-container'
          "
        >
          <div
            class="add-container"
            v-if="
              showIndex &&
                (navName === '新闻动态' ||
                  navName === '四化政策' ||
                  navName === '名单公示' ||
                  navName === '行业解决方案')
            "
            @click="handleAdd"
          >
            <div class="add-content pointer">
              <img :src="'/addContent.png' | staticMedia" alt="" />
              添加内容
            </div>
          </div>
          <!-- 四化诊断 或者 数字化诊断 -->
          <div v-if="navName === '四化诊断' || navName === '数字化诊断'">
            <fourDiagnosis></fourDiagnosis>
          </div>
          <!-- 四化改造 -->
          <fourRemodel v-if="navName === '四化改造'"></fourRemodel>
          <div
            class="list-content"
            v-for="(item, index) in dataList"
            :key="index * currentPage"
            @click="handleDetail(item)"
          >
            <!-- 新闻动态、行业解决方案 -->
            <div
              class="news-item"
              v-if="
                showIndex &&
                  (navName === '新闻动态' || navName === '行业解决方案')
              "
            >
              <newsUpdatesItem
                :itemDetail="item"
                @editContent="handleAdd"
                @openDeleteDialog="openDeleteDialog(item.id)"
              >
              </newsUpdatesItem>
            </div>
            <!-- 四化政策 -->
            <div class="news-item" v-if="showIndex && navName === '四化政策'">
              <fourPoliciesItem
                :itemDetail="item"
                @editContent="handleAdd"
                @openDeleteDialog="openDeleteDialog(item.id)"
              >
              </fourPoliciesItem>
            </div>
            <!-- 名单公示 -->
            <div class="news-item" v-if="showIndex && navName === '名单公示'">
              <listPublicItem
                :itemDetail="item"
                @editContent="handleAdd"
                @openDeleteDialog="openDeleteDialog(item.id)"
              >
              </listPublicItem>
            </div>
            <!-- 优秀案例 -->
            <div class="news-item" v-if="showIndex && navName === '优秀案例'">
              <excellentCaseItem
                :itemDetail="item"
                :dataList="dataList"
                @editContent="handleAdd"
                :isSelectedAll="isSelectedAll"
                @setSelectedId="getSelectedId"
                @openDeleteDialog="openDeleteDialog(item.id)"
              >
              </excellentCaseItem>
              <!-- <excellentCaseItem :itemDetail="item" :dataList="dataList" @editContent="handleAdd"
                                @openDeleteDialog="openDeleteDialog(item.id)">
                            </excellentCaseItem> -->
            </div>
          </div>
          <!-- 分页 -->
          <Pagination
            class="pagination"
            :total="total"
            :currentPage="currentPage"
            :pageSize="+pageSize"
            :layout="'prev, pager, next'"
            @pageChange="getList"
            v-if="
              showIndex &&
                this.total > 10 &&
                (navName === '新闻动态' ||
                  navName === '四化政策' ||
                  navName === '行业解决方案' ||
                  navName === '优秀案例' ||
                  navName === '名单公示')
            "
          >
          </Pagination>
          <!-- 优秀案例发布 -->
          <div
            class="checkbox-container"
            v-if="
              showIndex && navName === '优秀案例' && this.dataList.length > 0
            "
          >
            <div class="check-all" @click="handleCheck">
              <div class="checkbox">
                <img
                  :src="
                    this.isSelectedAll
                      ? 'choose.png'
                      : 'nochoose.png' | staticMedia
                  "
                  alt=""
                />
              </div>
              <div class="">全选</div>
            </div>
            <el-button class="widthBtn btn primaryBtn1" @click="handlePublish"
              >发布</el-button
            >
          </div>
          <!-- 添加内容 -->
          <div class="add-item" v-if="!showIndex">
            <addContentItem
              :addOrEdit="add ? '添加' : '修改'"
              :navName="navName"
              :editId="editId"
              @addSuccess="addSuccess"
            >
            </addContentItem>
          </div>
        </div>
      </div>
    </div>
    <!-- 在 components 中定义的 deleteDialog 可直接在页面中引入 -->
    <deleteDialog
      ref="delDialogShow"
      @delSuccess="handleDelSuccess"
    ></deleteDialog>
  </div>
</template>

<script>
import newsUpdatesItem from "./newsUpdatesItem";
import fourPoliciesItem from "./fourPoliciesItem";
import listPublicItem from "./listPublicItem";
import excellentCaseItem from "./excellentCaseItem";
import Pagination from "@/components/Pagination";
import addContentItem from "./addContentItem";
// 引入 deleteDialog 删除组件
import deleteDialog from "./deleteDialog";
import { mapGetters } from "vuex";
import { getIndexList, publishContent } from "@/api/zf";
import fourDiagnosis from "./dxxcx/index";
import fourRemodel from "./fourRemodel/index";
export default {
  name: "government",
  components: {
    fourDiagnosis,
    fourRemodel,
    newsUpdatesItem,
    fourPoliciesItem,
    listPublicItem,
    excellentCaseItem,
    Pagination,
    addContentItem,
    deleteDialog,
  },
  data() {
    return {
      navName: sessionStorage.getItem("navName"),
      add: false,
      showIndex: true,
      dataList: [],
      total: 0,
      page: 1,
      currentPage: 1,
      // 默认10个
      pageSize: 10,
      editId: "",
      isSelectedAll: false,
      selectedId: "", // 发布为优秀案例的id
      unselectedId: "", // 发布为经典案例的id
    };
  },
  watch: {
    getNavName(nv) {
      if (nv) {
        this.navName = nv;
      }
      this.postList();
    },
    "$store.state.navName": {
      handler: function(newVal, oldVal) {
        console.log(newVal, oldVal);
      },
    },
  },
  computed: {
    ...mapGetters(["getNavName"]),
  },
  created() {
    this.postList();
    this.navName = sessionStorage.getItem("navName");
    if (this.navName == "课程发布") {
      this.$router.push({ path: "/government/course" });
    } else if (this.navName == "审批中心") {
      this.$router.push({ path: "/government/approval" });
    }
  },
  methods: {
    // 作为删除组件删除成功之后通过自定义方法 delSuccess 触发的方法，作为删除成功的反馈，提升用户体验感。
    handleDelSuccess() {
      this.$message.success("删除成功");
      this.getList();
      // setTimeout(() => {
      //     this.$router.go()
      // }, 2000);
    },
    postList() {
      if (
        this.navName === "四化诊断" ||
        this.navName === "四化改造" ||
        this.navName === "数字化诊断"
      ) {
        console.log("切换", this.navName);

        this.showIndex = true;
      } else if (this.navName === "名单公示") {
        this.showIndex = true;
        this.dataList = [];
        this.total = 0;
        this.currentPage = 1;
        this.pageSize = 8;
        this.getList();
      } else {
        this.showIndex = true;
        this.dataList = [];
        this.total = 0;
        this.currentPage = 1;
        this.pageSize = 10;
        this.getList();
      }
    },
    handleDetail(v) {
      console.log(v, "点击详情");
    },
    handleAdd(v, item) {
      this.showIndex = false;
      this.add = v === true ? false : true;
      this.editId = item === undefined ? "" : item.id + "";
    },
    openDeleteDialog(id) {
      this.$refs.delDialogShow.openDialog(id);
    },
    // 列表
    getList(v) {
      this.navName = this.navName === null ? "新闻动态" : this.navName;
      getIndexList({
        cate_id:
          this.navName === "新闻动态"
            ? "24,26"
            : this.navName === "四化政策"
            ? "25,29"
            : this.navName === "名单公示"
            ? 27
            : this.navName === "行业解决方案"
            ? 30
            : this.navName === "优秀案例"
            ? "31,35"
            : "",
        page: v ? v : this.page,
        pageSize: +this.pageSize,
      })
        .then((res) => {
          if (res.code === 1) {
            console.log("this.datalist", res);
            this.dataList = res.data.data;
            this.total = res.data.total;
            this.pageSize = res.data.per_page;
            this.currentPage = +res.data.current_page;
          }
        })
        .finally(() => {
          // 加个判断 如果是名单公示应该是8个每页
          if (this.navName === "名单公示") {
            this.pageSize = 8;
          }
        });
    },
    addSuccess(v) {
      this.showIndex = true;
      if (v === true) {
        this.getList();
      }
      // 每次调用这个回调的时候刷新下，这个司马写法不想搞
      this.$router.go(0);
    },
    getSelectedId(v, arr_nochoose) {
      this.selectedId = v.join(",");
      this.unselectedId = arr_nochoose.join(",");
    },
    handleCheck() {
      this.isSelectedAll = !this.isSelectedAll;
    },
    // 优秀案例发布
    handlePublish() {
      // if (this.selectedId === "") {
      //     this.$message.error("请至少选中一个")
      // } else {
      // 选中的id用，隔开之后 type传值31   标识设置为优秀案例
      // 优秀案例 31 经典案例 35
      publishContent({
        // type: '31',
        // id: this.selectedId,
        yxal_ids: this.selectedId,
        dxal_ids: this.unselectedId,
      }).then((res) => {
        if (res.code === 1) {
          this.$message.success("发布成功");
          this.getList();
        }
      });
      // }
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background-color: #eff4fe;

  .same-container {
    width: 100%;
    height: 100%;
    background: #f0f5ff;
    background-size: 1920px 1000px;
    background-attachment: fixed;

    .size-container {
      width: 100%;
      height: 100%;
      margin: 0 auto;
      min-height: 700px;

      .list-container {
        overflow: hidden;
        position: relative;
        width: 84%;
        min-width: 1200px;
        margin: auto;
        padding: 10px;
        box-sizing: border-box;

        .list-content {
          width: 100%;
          height: 100%;
          display: flex;
          flex-wrap: wrap;
          box-sizing: border-box;

          .news-item {
            width: 100%;
            height: 100%;
            margin: 0 auto;
          }
        }
      }

      .add-container {
        width: 96px;
        height: 114px;
        background-color: #fff;
        line-height: 50px;
        border-radius: 8px;
        text-align: center;
        box-shadow: 0 0 10px #dedede;
        position: fixed;
        bottom: 250px;
        right: 100px;
        z-index: 98;

        .add-content {
          display: inline-block;
          color: #333;
          width: 100%;
          font-size: 14px;
          padding: 20px 0 0;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          &:hover {
            border-radius: 8px;
            background-color: #205dc3;
            color: #fff;
          }

          img {
            width: 50px;
            height: 50px;
            margin: 0 auto;
          }
        }
      }

      .public-container {
        overflow: hidden;
        position: relative;
        width: 100%;
        min-width: 1200px;
        margin: auto;
        padding: 0 7%;
        box-sizing: border-box;
        display: flex;
        flex-wrap: wrap;
        // align-items: center;

        .list-content {
          width: 24.05%;
          height: 100%;
          margin-right: 1%;

          &:nth-child(4n - 3) {
            margin-right: 0;
          }

          .news-item {
            width: 100%;
            height: 100%;
            margin: 0 auto;
          }
        }

        .pagination-container {
          width: 100%;
        }
      }

      .add-item {
        width: 100%;
        height: 100%;
        margin: 0 auto;
      }

      .checkbox-container {
        display: flex;
        align-items: center;
        padding: 0 30px;

        .check-all {
          margin-right: 20px;
          display: flex;

          .checkbox {
            width: 20px;
            height: 20px;
            vertical-align: middle;
            cursor: pointer;
            margin: auto 20px auto 0;

            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
}

::v-deep .el-pagination {
  width: 100%;
}

// ::v-deep .el-pagination .btn-next,
// ::v-deep .el-pagination .btn-prev,
// ::v-deep .el-pager li,
// ::v-deep .el-pagination button:disabled {
//     background-color: transparent !important;
// }
</style>
