<template>
  <div class="main">
    <div class="container">
      <el-tabs v-model="verifyType" @tab-click="handleChangeVerifyType">
        <el-tab-pane label="审批备案企业" name="spbaqy"> </el-tab-pane>
        <el-tab-pane label="内容审批" name="nrsp"> </el-tab-pane>
      </el-tabs>

      <div class="verify-form">
        <el-form
          :inline="true"
          :model="verifyForm_qy"
          v-if="verifyType == 'spbaqy'"
        >
          <el-form-item label="公司名称">
            <el-input
              v-model="verifyForm_qy.search"
              placeholder="请输入公司名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="诊断机构名称">
            <el-input
              v-model="verifyForm_qy.institution"
              placeholder="请输入诊断机构名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="行业">
            <el-select
              v-model="verifyForm_qy.trade_id"
              placeholder="请选择行业"
              clearable
              style="width: 150px;"
            >
              <el-option
                v-for="item in hyList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="区域">
            <el-select
              v-model="verifyForm_qy.area"
              placeholder="请选择区域"
              clearable
              style="width: 120px;"
            >
              <el-option
                v-for="item in areaList"
                :key="item.id"
                :label="item.name_cn"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="审批状态">
            <el-select
              v-model="verifyForm_qy.status"
              placeholder="请选择审批状态"
              clearable
              style="width: 150px;"
            >
              <el-option
                v-for="(item, index) in statusList"
                :key="index"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button
              class="primaryBtn1"
              type="primary"
              @click="handleSubmit_qy"
              >查询</el-button
            >
          </el-form-item>
        </el-form>
        <el-form
          :inline="true"
          :model="verifyForm_nr"
          v-if="verifyType == 'nrsp'"
        >
          <el-form-item label="审批类型">
            <el-select
              v-model="verifyForm_nr.check_type"
              placeholder="请选择审批类型"
              clearable
            >
              <el-option
                v-for="(item, index) in status_nrList"
                :key="index"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button
              class="primaryBtn1"
              type="primary"
              @click="handleSubmit_nr"
              >查询</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <div class="verify-table">
        <template v-if="verifyType == 'spbaqy'">
          <el-table
            :data="tableData_qy"
            border
            style="width: 100%"
            v-if="verifyType == 'spbaqy'"
            @selection-change="handleQYSelectionChange"
            key="id"
          >
            <el-table-column type="selection" width="55" key="qy_select">
            </el-table-column>
            <el-table-column type="index" label="序号" width="55" key="qy_序号">
            </el-table-column>
            <el-table-column
              prop="company_name"
              label="公司名称"
              show-overflow-tooltip
              key="qy_公司名称"
            >
            </el-table-column>
            <el-table-column
              prop="code"
              label="机构代码"
              show-overflow-tooltip
              key="qy_机构代码"
            >
            </el-table-column>
            <el-table-column
              label="行政区域"
              show-overflow-tooltip
              key="qy_行政区域"
            >
              <template slot-scope="scope">
                <span>
                  {{
                    scope.row.province +
                      " " +
                      scope.row.city +
                      " " +
                      scope.row.area
                  }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              prop="trade"
              label="行业"
              show-overflow-tooltip
              key="qy_行业"
            >
            </el-table-column>
            <el-table-column
              prop="product_type"
              label="产品类型"
              show-overflow-tooltip
              key="qy_产品类型"
            >
            </el-table-column>
            <el-table-column
              prop="remark"
              label="备注"
              show-overflow-tooltip
              key="qy_备注"
            >
            </el-table-column>
            <el-table-column
              prop="contract_url"
              label="合同文件"
              width="128"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <el-button
                  type="text"
                  v-if="scope.row.contract_url"
                  @click="handlePreviewContractUrl(scope.row.contract_url)"
                  >查看合同文件</el-button
                >
                <span v-else>暂无文件</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="name"
              label="诊断机构"
              show-overflow-tooltip
              key="qy_诊断机构"
            >
            </el-table-column>
            <el-table-column
              prop="contact"
              label="联系人"
              show-overflow-tooltip
              key="qy_联系人"
            >
            </el-table-column>
            <el-table-column
              prop="phone"
              label="联系方式"
              show-overflow-tooltip
              key="qy_联系方式"
            >
            </el-table-column>
            <el-table-column
              prop="status"
              label="审批状态"
              show-overflow-tooltip
              key="qy_审批状态"
            >
              <template slot-scope="scope">
                <!-- {{
                scope.row.status == 0
                  ? "审批中"
                  : scope.row.status == 1
                  ? "已通过"
                  : "已拒绝"
              }} -->
                <span v-if="scope.row.status == 0">审批中</span>
                <span v-if="scope.row.status == 1">已通过</span>
                <span v-if="scope.row.status == 2">已拒绝</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" key="qy_操作">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  @click="handleQYPass(scope.row)"
                  v-if="scope.row.status == 0"
                  >通过</el-button
                >
                <el-button
                  type="text"
                  @click="handleQYReject(scope.row)"
                  v-if="scope.row.status == 0"
                  >拒绝</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </template>
        <template v-if="verifyType == 'nrsp'">
          <el-table :data="tableData_nr" border style="width: 100%" key="id">
            <!-- <el-table-column type="selection" width="55"> </el-table-column> -->
            <el-table-column width="55" key="nr_empty"> </el-table-column>
            <el-table-column type="index" label="序号" width="55" key="nr_序号">
            </el-table-column>
            <el-table-column prop="type" label="内容分类" key="nr_内容分类">
              <template slot-scope="scope">
                <span v-if="scope.row.type == '1'">新闻动态</span>
                <span v-if="scope.row.type == '2'">通知公告</span>
                <span v-if="scope.row.type == '3'">政策原文</span>
                <span v-if="scope.row.type == '4'">政策解读</span>
                <span v-if="scope.row.type == '5'">名单公示</span>
                <span v-if="scope.row.type == '6'">行业解决方案</span>
                <span v-if="scope.row.type == '7'">经典案例</span>
              </template>
            </el-table-column>
            <el-table-column label="内容详情" key="nr_内容详情">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  @click="getNRDetail(scope.row.link_id, scope.row.type)"
                  >详情</el-button
                >
              </template>
            </el-table-column>
            <el-table-column label="审批状态" key="nr_审批状态">
              <template slot-scope="scope">
                <div v-if="scope.row.check_data.length == 1">
                  <span v-if="scope.row.check_data[0].status == 0">审核中</span>
                  <span v-if="scope.row.check_data[0].status == 1">已通过</span>
                  <span v-if="scope.row.check_data[0].status == 2">已拒绝</span>
                </div>
                <div v-if="scope.row.check_data.length == 2">
                  <span
                    v-if="
                      scope.row.check_data[0].status == 0 &&
                        scope.row.check_data[1].status == 0
                    "
                    >审批人审核中</span
                  >
                  <span
                    v-if="
                      scope.row.check_data[0].status == 1 &&
                        scope.row.check_data[1].status == 0
                    "
                    >审定人审核中</span
                  >
                  <span
                    v-if="
                      scope.row.check_data[0].status == 1 &&
                        scope.row.check_data[1].status == 1
                    "
                    >已通过</span
                  >
                  <span
                    v-if="
                      scope.row.check_data[0].status == 2 &&
                        scope.row.check_data[1].status == 0
                    "
                    >审批人已拒绝</span
                  >
                  <span
                    v-if="
                      scope.row.check_data[0].status == 1 &&
                        scope.row.check_data[1].status == 2
                    "
                    >审定人已拒绝</span
                  >
                  <span
                    v-if="
                      scope.row.check_data[0].status == 2 &&
                        scope.row.check_data[1].status == 2
                    "
                    >已拒绝</span
                  >
                </div>
              </template>
            </el-table-column>
            <el-table-column label="审批人" key="nr_审批人">
              <template slot-scope="scope">
                <div v-if="scope.row.check_data.length == 1">
                  {{ scope.row.check_data[0].check_user.username }}
                </div>
                <div slot-scope="scope" v-if="scope.row.check_data.length == 2">
                  {{
                    scope.row.check_data[0].check_user.username +
                      ", " +
                      scope.row.check_data[1].check_user.username
                  }}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="审批流详情" key="nr_审批流详情">
              <template slot-scope="scope">
                <el-button type="text" @click="getNRVerifyDetail(scope.row)"
                  >详情</el-button
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="create_time"
              label="创建时间"
              key="nr_创建时间"
            >
              <template slot-scope="scope">
                {{ formatDateTime(scope.row.create_time) }}
              </template>
            </el-table-column>
            <el-table-column label="操作" key="nr_操作">
              <template slot-scope="scope">
                <template v-if="scope.row.check_data.length == 1">
                  <el-button
                    type="text"
                    @click="handleNRPass(scope.row)"
                    v-if="
                      scope.row.check_data[0].status == 0 &&
                        scope.row.check_data[0].status != 2
                    "
                    >通过</el-button
                  >
                  <el-button
                    type="text"
                    @click="handleNRReject(scope.row)"
                    v-if="
                      scope.row.check_data[0].status == 0 &&
                        scope.row.check_data[0].status != 2
                    "
                    >拒绝</el-button
                  >
                </template>
                <template v-if="scope.row.check_data.length == 2">
                  <el-button
                    type="text"
                    @click="handleNRPass(scope.row)"
                    v-if="
                      (scope.row.check_data[0].status == 0 ||
                        scope.row.check_data[1].status == 0) &&
                        scope.row.check_data[0].status != 2 &&
                        scope.row.check_data[1].status != 2
                    "
                    >通过</el-button
                  >
                  <el-button
                    type="text"
                    @click="handleNRReject(scope.row)"
                    v-if="
                      (scope.row.check_data[0].status == 0 ||
                        scope.row.check_data[1].status == 0) &&
                        scope.row.check_data[0].status != 2 &&
                        scope.row.check_data[1].status != 2
                    "
                    >拒绝</el-button
                  >
                </template>
              </template>
            </el-table-column>
          </el-table>
        </template>
      </div>
      <div class="verify-pager flex bewteen vertical">
        <div style="width: 300px;margin: 10px 0;" v-if="verifyType == 'spbaqy'">
          <el-button type="primary" @click="idsPass">批量通过</el-button>
          <el-button type="danger" @click="idsReject">批量拒绝</el-button>
        </div>
        <el-pagination
          background
          layout="prev, pager, next"
          :total="total_qy"
          :current-page="page_qy"
          :page-size="pageSize"
          @current-change="handleCurrentChange_qy"
          v-if="verifyType == 'spbaqy' && total_qy > 0"
        >
        </el-pagination>
        <el-pagination
          background
          layout="prev, pager, next"
          :total="total_nr"
          :current-page="page_nr"
          :page-size="pageSize"
          @current-change="handleCurrentChange_nr"
          v-if="verifyType == 'nrsp' && total_nr > 0"
        >
        </el-pagination>
      </div>
    </div>

    <!-- 审批流详情弹窗开始 -->
    <el-dialog
      title="审批流详情"
      v-if="verifyDialog"
      :visible.sync="verifyDialog"
      width="68%"
      :before-close="handleCloseDialog"
      :close-on-click-modal="false"
    >
      <div class="approval-main">
        <div class="approval-list">
          <div class="approval-item">
            <div class="approval-title">发起请求</div>
            <div class="approval-body">
              发起人：
              <span class="body-person">{{
                verifyNRObj.user.username || "-"
              }}</span>
            </div>
          </div>
          <div class="approval-item">
            <div class="approval-title">
              <!-- <span>{{ verifyNRObj.check_data[0].check_name }}：</span> -->
              <span>内容审核审批人</span>
            </div>
            <div class="approval-body">
              审批人：
              <span class="body-person">{{
                verifyNRObj.check_data[0].check_user.username || "-"
              }}</span>
              <span
                class="body-status wait"
                v-if="verifyNRObj.check_data[0].status == 0"
                >待审批</span
              >
              <span
                class="body-status pass"
                v-if="verifyNRObj.check_data[0].status == 1"
                >已通过</span
              >
              <span
                class="body-status reject"
                v-if="verifyNRObj.check_data[0].status == 2"
                >已拒绝</span
              >
            </div>
            <div class="approval-body">
              审批人备注：
              <span class="body-person">{{
                verifyNRObj.check_data[0].remark || "-"
              }}</span>
              <span class="body-time">{{
                formatDateTime(verifyNRObj.check_data[0].check_time) || "-"
              }}</span>
            </div>
          </div>
          <div class="approval-item" v-if="verifyNRObj.check_data.length == 2">
            <div class="approval-title">
              <!-- <span>{{ verifyNRObj.check_data[1].check_name }}：</span> -->
              <span>内容审核审定人</span>
            </div>
            <div class="approval-body">
              审定人：
              <span class="body-person">{{
                verifyNRObj.check_data[1].check_user.username || "-"
              }}</span>
              <span
                class="body-status wait"
                v-if="verifyNRObj.check_data[1].status == 0"
                >待审批</span
              >
              <span
                class="body-status pass"
                v-if="verifyNRObj.check_data[1].status == 1"
                >已通过</span
              >
              <span
                class="body-status reject"
                v-if="verifyNRObj.check_data[1].status == 2"
                >已拒绝</span
              >
            </div>
            <div class="approval-body">
              审定人备注：
              <span class="body-person">{{
                verifyNRObj.check_data[1].remark || "-"
              }}</span>
              <span class="body-time">{{
                formatDateTime(verifyNRObj.check_data[1].check_time) || "-"
              }}</span>
            </div>
          </div>
          <!-- <div class="approval-item">
            <div class="approval-title">县（区）级审批</div>
            <div class="approval-body">
              审批人：
              <span class="body-person">{{ infoObj.approval }}</span>
            </div>
          </div> -->
          <div class="approval-item">
            <div class="approval-title">流程结束</div>
            <div class="approval-body"></div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="cancleBtn1" @click="handleCloseDialog"
          >取 消</el-button
        >
        <el-button class="parmaryBtn1" type="primary" @click="handleCloseDialog"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 
      审批流详情弹窗结束
      内容详情弹窗开始
     -->
    <el-dialog
      title="内容详情"
      v-if="contentDialog"
      :visible.sync="contentDialog"
      width="68%"
      :before-close="handleCloseDialog"
      :close-on-click-modal="false"
    >
      <div class="content-body">
        <div class="content-body-item">
          <div class="content-body-title">内容分类</div>
          <div class="content-body-content">{{ contentNRObj.type || "-" }}</div>
        </div>
        <div class="content-body-item">
          <div class="content-body-title">列表图片</div>
          <div class="content-body-content">
            <img
              class="content-image"
              style="max-width: 300px;"
              :src="contentNRObj.image | contentMedia"
              alt=""
            />
          </div>
        </div>
        <div class="content-body-item">
          <div class="content-body-title">标题</div>
          <div class="content-body-content">
            {{ contentNRObj.title || "-" }}
          </div>
        </div>
        <div class="content-body-item">
          <div class="content-body-title">副标题</div>
          <div class="content-body-content">
            {{ contentNRObj.subheading || "-" }}
          </div>
        </div>
        <div class="content-body-item">
          <div class="content-body-title">来源</div>
          <div class="content-body-content">
            {{ contentNRObj.source || "-" }}
          </div>
        </div>
        <div class="content-body-item">
          <div class="content-body-title">详情</div>
          <div class="content-body-content">
            <span
              class="content-html"
              v-html="contentNRObj.content ? contentNRObj.content : '-'"
            ></span>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="cancleBtn1" @click="handleCloseDialog"
          >取 消</el-button
        >
        <el-button class="parmaryBtn1" type="primary" @click="handleCloseDialog"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 
      内容详情弹窗结束
      输入拒绝原因弹窗开始   
    -->
    <el-dialog
      title="提示"
      :visible.sync="rejectFormDialog"
      width="500px"
      :before-close="handleCloseDialog"
      :close-on-click-modal="false"
      class="rejectForm"
    >
      <el-form :inline="true" :model="rejectForm" style="width: 100%;">
        <el-form-item>
          <el-input
            type="textarea"
            maxlength="150"
            rows="6"
            show-word-limit
            v-model="rejectForm.remark"
            placeholder="请输入拒绝原因"
          ></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCloseDialog">取 消</el-button>
        <el-button type="primary" @click="doNRReject" v-if="rejectType == 1"
          >确 定</el-button
        >
        <el-button type="primary" @click="doQYReject" v-if="rejectType == 2"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 输入拒绝原因弹窗结束 -->
  </div>
</template>
<script>
// import configs from "@/config/index";
import { formatDateTime } from "@/utils/common";
import {
  getVerifyList_qy,
  getVerifyList_nr,
  verify_qy,
  verify_nr,
  getNRDetail,
} from "@/api/zf";
import { getArea } from "@/api/login";
export default {
  name: "ApprovalCenter",
  data() {
    return {
      verifyType: "spbaqy",

      // 行业列表
      hyList: [],
      // 区域列表
      areaList: [],
      // 审核状态列表
      // statusList: ["待审核", "审核通过", "审核拒绝"],
      statusList: [
        { label: "待审核", value: 0 },
        { label: "审核通过", value: 1 },
        { label: "审核拒绝", value: 2 },
      ],
      status_nrList: [
        { label: "待我审批", value: 1 },
        { label: "我发起的", value: 2 },
      ],

      // 搜索审批备案企业
      verifyForm_qy: {
        search: "",
        institution: "",
        trade_id: "",
        area: "",
        status: "",
        page: this.page_qy,
      },
      // 审批备案企业表格
      tableData_qy: [],
      total_qy: 0,
      page_qy: 1,

      pageSize: 10,

      // 搜索内容审批
      verifyForm_nr: {
        check_type: "",
        page: this.page_nr,
      },
      rejectForm: {
        remark: "",
      },
      rejectRow: {},
      rejectFormDialog: false,
      // 内容审批表格
      tableData_nr: [],
      total_nr: 0,
      page_nr: 1,
      // 内容审批-审批流详情
      verifyNRObj: {},
      verifyDialog: false,
      // 内容审批-内容详情
      contentNRObj: {},
      contentDialog: false,

      // 拒绝原因窗口类型 1-》内容 2-》 企业
      rejectType: 0,

      // 多选的id列表
      ids: [],
      // 多选的id列表状态
      idsStatus: [],

      // 是否正在进行审核操作
      isVerifing: false,
    };
  },
  created() {
    this.getVerifyList_qy();
    this.hyList = JSON.parse(sessionStorage.getItem("hylist"));
    // this.areaList = configs.areaOptions;
    this.getGUANGZHOUAreaList();
  },
  mounted() {
    this.$nextTick(() => {});
  },
  methods: {
    handlePreviewContractUrl(contractUrl) {
      let file = JSON.parse(contractUrl)[0];
      let url = file.url;
      let linkDom = document.createElement("a");
      linkDom.href = url;
      linkDom.target = "_blank";
      linkDom.download = file.name;
      linkDom.style.display = "none";
      linkDom.click();
    },
    // 懒得封装了  就这样吧
    idsPass() {
      let _this = this;
      if (
        _this.idsStatus.indexOf(2) != -1 ||
        _this.idsStatus.indexOf(1) != -1
      ) {
        _this.$message.error("不可审核已通过/已拒绝的审批");
        return false;
      }
      _this
        .$confirm("是否批量通过审批?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          // 如果还在进行审核操作，return
          if (this.isVerifing) {
            return false;
          }
          this.isVerifing = true;
          let pdata = {
            ids: _this.ids.join(","),
            // reason: _this.rejectForm.remark,
            type: "1",
          };
          verify_qy(pdata)
            .then((res) => {
              console.log("res", res);

              _this.$message({
                type: "success",
                message: "审批已通过",
              });
            })
            .catch((err) => {
              console.log("error!", err);

              _this.$message({
                type: "error",
                message: "操作失败",
              });
            })
            .finally(() => {
              _this.getVerifyList_qy();
              _this.isVerifing = false;
            });
        })
        .catch(() => {
          _this.isVerifing = false;
          _this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    idsReject() {
      let _this = this;
      if (
        _this.idsStatus.indexOf(2) != -1 ||
        _this.idsStatus.indexOf(1) != -1
      ) {
        _this.$message.error("不可审核已通过/已拒绝的审批");
        return false;
      }
      _this
        .$confirm("是否批量拒绝审批?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          // 如果还在进行审核操作，return
          if (this.isVerifing) {
            return false;
          }
          this.isVerifing = true;
          let pdata = {
            ids: _this.ids.join(","),
            // reason: _this.rejectForm.remark,
            type: "2",
          };
          verify_qy(pdata)
            .then((res) => {
              console.log("res", res);

              _this.$message({
                type: "success",
                message: "审批已拒绝",
              });
            })
            .catch((err) => {
              console.log("error!", err);

              _this.$message({
                type: "error",
                message: "操作失败",
              });
            })
            .finally(() => {
              _this.getVerifyList_qy();
              _this.isVerifing = false;
            });
        })
        .catch(() => {
          _this.isVerifing = false;
          _this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    handleQYSelectionChange(items) {
      console.log("items----------->", items);

      this.ids = items.map((item) => {
        return item.id;
      });
      this.idsStatus = items.map((item) => {
        return item.status;
      });
    },
    getGUANGZHOUAreaList() {
      let pdata = {
        pid: 236,
      };

      getArea(pdata).then((res) => {
        console.log("res", res);

        this.areaList = res.data;
      });
    },
    doNRReject() {
      let step = 0;
      if (this.rejectRow.check_data.length == 2) {
        if (this.rejectRow.check_data[0].status == 0) {
          step = 1;
        } else if (
          this.rejectRow.check_data[0].status == 1 &&
          this.rejectRow.check_data[1].status == 0
        ) {
          step = 2;
        } else {
          this.$message.error("该审核状态不可更改");
          return false;
        }
      } else if (this.rejectRow.check_data.length == 1) {
        if (this.rejectRow.check_data[0].status == 0) {
          step = 1;
        } else if (this.rejectRow.check_data[0].status == 1) {
          this.$message.error("该审核状态不可更改");
          return false;
        }
      }
      // 如果还在进行审核操作，return
      if (this.isVerifing) {
        return false;
      }
      this.isVerifing = true;
      let _this = this;
      let pdata = {
        id: _this.rejectRow.id,
        status: "2",
        remark: _this.rejectForm.remark,
        step: step,
        type: this.rejectRow.type,
      };
      verify_nr(pdata)
        .then((res) => {
          console.log("res", res);

          _this.$message({
            type: "success",
            message: "审批已拒绝",
          });
        })
        .catch((err) => {
          console.log("error!", err);

          _this.$message({
            type: "error",
            message: "操作失败",
          });
        })
        .finally(() => {
          _this.handleCloseDialog();
          _this.getVerifyList_nr();
          _this.isVerifing = false;
        });
    },
    doQYReject() {
      // 如果还在进行审核操作，return
      if (this.isVerifing) {
        return false;
      }
      this.isVerifing = true;
      let _this = this;
      let pdata = {
        ids: _this.rejectRow.id,
        reason: _this.rejectForm.remark,
        type: "2",
      };
      verify_qy(pdata)
        .then((res) => {
          console.log("res", res);

          _this.$message({
            type: "success",
            message: "审批已拒绝",
          });
        })
        .catch((err) => {
          console.log("error!", err);

          _this.$message({
            type: "error",
            message: "操作失败",
          });
        })
        .finally(() => {
          _this.handleCloseDialog();
          _this.getVerifyList_qy();
          _this.isVerifing = false;
        });
    },
    handleCloseDialog() {
      this.verifyDialog = false;
      this.contentDialog = false;
      this.rejectFormDialog = false;
      this.rejectForm = {};
      this.rejectRow = {};
    },
    // 查看内容审批流详情
    getNRVerifyDetail(row) {
      let _this = this;
      _this.verifyNRObj = row;
      _this.verifyDialog = true;
    },
    // 查看内容审批详情
    getNRDetail(id, type) {
      let _this = this;

      let typeString = "";
      if (type == "1") {
        typeString = "新闻动态";
      } else if (type == "2") {
        typeString = "通知公告";
      } else if (type == "3") {
        typeString = "政策原文";
      } else if (type == "4") {
        typeString = "政策解读";
      } else if (type == "5") {
        typeString = "名单公示";
      } else if (type == "6") {
        typeString = "行业解决方案";
      } else if (type == "7") {
        typeString = "经典案例";
      }
      let pdata = { id: id };
      getNRDetail(pdata).then((res) => {
        console.log("res", res, typeString);
        _this.contentNRObj = res.data;
        _this.contentNRObj.type = typeString;
      });

      // _this.contentNRObj = row;

      _this.contentDialog = true;
    },
    // 内容审批通过/拒绝
    handleNRPass(row) {
      let step = 0;
      if (row.check_data.length == 2) {
        if (row.check_data[0].status != 0 && row.check_data[1].status != 0) {
          this.$message.error("该审核状态不可更改");
          return false;
        }

        if (row.check_data[0].status == 0) {
          step = 1;
        } else if (
          row.check_data[0].status == 1 &&
          row.check_data[1].status == 0
        ) {
          step = 2;
        } else {
          this.$message.error("该审核状态不可更改");
          return false;
        }
      } else if (row.check_data.length == 1) {
        if (row.check_data[0].status != 0) {
          this.$message.error("该审核状态不可更改");
          return false;
        }

        if (row.check_data[0].status == 0) {
          step = 1;
        } else if (row.check_data[0].status == 1) {
          this.$message.error("该审核状态不可更改");
          return false;
        }
      }

      // 如果还在进行审核操作，return
      if (this.isVerifing) {
        return false;
      }
      this.isVerifing = true;
      let _this = this;
      _this
        .$confirm("是否通过该审批?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          let pdata = {
            id: row.id,
            status: "1",
            step: step,
            remark: "",
          };
          verify_nr(pdata)
            .then((res) => {
              console.log("res", res);

              _this.$message({
                type: "success",
                message: "审批成功",
              });
            })
            .catch((err) => {
              console.log("error!", err);

              _this.$message({
                type: "error",
                message: "审批失败",
              });
            })
            .finally(() => {
              _this.getVerifyList_nr();
              _this.isVerifing = false;
            });
        })
        .catch(() => {
          _this.isVerifing = false;
          _this.$message({
            type: "info",
            message: "已取消该操作",
          });
        });
    },
    handleNRReject(row) {
      if (row.check_data.length == 2) {
        if (row.check_data[0].status != 0 && row.check_data[1].status != 0) {
          this.$message.error("该审核状态不可更改");
          return false;
        }
      } else if (row.check_data.length == 1) {
        if (row.check_data[0].status != 0) {
          this.$message.error("该审核状态不可更改");
          return false;
        }
      }
      this.rejectRow = row;
      this.rejectFormDialog = true;
      this.rejectType = 1;
    },
    // 审批备案企业通过/拒绝
    handleQYPass(row) {
      console.log("row", row);

      if (row.status != 0) {
        this.$message.error("该审核状态不可更改");
        return false;
      }
      // 如果还在进行审核操作，return
      if (this.isVerifing) {
        return false;
      }
      this.isVerifing = true;
      let _this = this;
      _this
        .$confirm("是否通过该审批?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          let pdata = {
            ids: row.id,
            type: "1",
          };
          verify_qy(pdata)
            .then((res) => {
              console.log("res", res);

              _this.$message({
                type: "success",
                message: "审批成功",
              });
            })
            .catch((err) => {
              console.log("error!", err);

              _this.$message({
                type: "error",
                message: "审批失败",
              });
            })
            .finally(() => {
              _this.getVerifyList_qy();
              _this.isVerifing = false;
            });
        })
        .catch(() => {
          _this.isVerifing = false;
          _this.$message({
            type: "info",
            message: "已取消该操作",
          });
        });
    },
    handleQYReject(row) {
      if (row.status != 0) {
        this.$message.error("该审核状态不可更改");
        return false;
      }

      this.rejectRow = row;
      this.rejectFormDialog = true;
      this.rejectType = 2;

      // let _this = this;
      // _this
      //   .$prompt("是否拒绝该审批?", "提示", {
      //     confirmButtonText: "确定",
      //     cancelButtonText: "取消",
      //   })
      //   .then(({ val }) => {
      //     let pdata = {
      //       ids: row.id,
      //       reason: val,
      //       type: "2",
      //     };
      //     verify_qy(pdata)
      //       .then((res) => {
      //         console.log("res", res);

      //         _this.$message({
      //           type: "success",
      //           message: "审批已拒绝",
      //         });
      //       })
      //       .catch((err) => {
      //         console.log("error!", err);

      //         _this.$message({
      //           type: "error",
      //           message: "操作失败",
      //         });
      //       })
      //       .finally(() => {
      //         this.getVerifyList_qy();
      //       });
      //   })
      //   .catch(() => {
      //     _this.$message({
      //       type: "info",
      //       message: "已取消该操作",
      //     });
      //   });
    },
    getVerifyList_qy() {
      let pdata = {
        ...this.verifyForm_qy,
        page: this.page_qy,
      };
      getVerifyList_qy(pdata).then((res) => {
        console.log("res", res);
        this.tableData_qy = res.data.data;
        this.total_qy = res.data.total;
        this.itemKey_qy = Math.random() + "_qy";
      });
    },
    getVerifyList_nr() {
      let pdata = {
        ...this.verifyForm_nr,
        page: this.page_nr,
      };
      getVerifyList_nr(pdata).then((res) => {
        console.log("res", res);
        this.tableData_nr = res.data.list.data;
        this.total_nr = res.data.list.total;
        this.itemKey_nr = Math.random() + "_nr";
      });
    },
    // 分页器pagechange
    handleCurrentChange_qy(page) {
      this.page_qy = page;
      this.getVerifyList_qy();
    },
    handleCurrentChange_nr(page) {
      this.page_nr = page;
      this.getVerifyList_nr();
    },
    // 查询
    handleSubmit_nr() {
      this.getVerifyList_nr();
    },
    handleSubmit_qy() {
      this.getVerifyList_qy();
    },
    formatDateTime(timetemp) {
      return formatDateTime(timetemp);
    },
    handleChangeVerifyType(obj) {
      // 每次切换都发一次请求 相当于刷新
      if (obj.name == "spbaqy") {
        this.getVerifyList_qy();
      } else {
        this.getVerifyList_nr();
      }
      this.verifyType = obj.name;
    },
  },
};
</script>
<style lang="less" scoped>
::v-deep .el-pagination {
  width: 100%;
  margin: 10px auto;
  text-align: right;
  box-sizing: border-box;
}
::v-deep .el-table td.el-table__cell div {
  text-align: center;
}
::v-deep .el-tabs__item {
  color: #666;
  font-size: 22px;
  margin-bottom: 8px;
  &.is-active {
    color: #333;
    font-weight: 600;
  }
  &:not(.is-active):hover {
    color: #666;
  }
}
::v-deep .el-tabs__active-bar {
  height: 3px;
  // margin-top: 8px;
}
::v-deep .el-tabs__nav-wrap::after {
  height: 1px;
  background-color: #d8d8d8;
}
::v-deep .el-form-item {
  margin-bottom: 10px;
}
::v-deep .el-table th.el-table__cell > .cell {
  text-align: center;
}
::v-deep .el-table th.el-table__cell.is-leaf {
  color: #333;
  background-color: #eef2ff;
  border-color: #d8d8d8;
}
::v-deep .el-table--border {
  border-color: #d8d8d8;
}
.main {
  .container {
    width: 84%;
    margin: 0 auto;
    padding: 30px 25px 0;
    background-color: #fff;
    border-radius: 10px;
    box-sizing: border-box;
    overflow: hidden;
  }
}
.approval-main {
  border-left: 2px solid #205dc3;
  margin: 20px 50px;

  .approval-list {
    padding-left: 50px;
    .approval-item {
      .approval-title {
        position: relative;

        color: #666;
        font-size: 16px;
        line-height: 16px;
        margin-bottom: 20px;
        margin-top: 20px;

        &::before {
          content: "";
          width: 16px;
          height: 16px;
          border-radius: 18px;
          background-color: #205dc3;

          position: absolute;
          left: -59px;
          top: 0;
          bottom: 0;
          margin: auto 0;
        }
      }
      .approval-body {
        margin-bottom: 10px;
        font-size: 16px;
        line-height: 32px;
        display: flex;
        align-items: flex-start;
        .body-status {
          display: inline-block;
          // width: 150px;
          line-height: 1;
          padding: 3px 8px;
          text-align: center;
          border-radius: 3px;
          font-size: 12px;
          margin: 6px 0 0 15px;
          &.wait {
            color: #666;
            border: 1px solid #666;
          }
          &.pass {
            color: #67c23a;
            border: 1px solid #67c23a;
          }
          &.reject {
            color: #f56c6c;
            border: 1px solid #f56c6c;
          }
        }
        .body-time {
          margin-left: 15px;
          font-size: 12px;
          color: #666;
        }
        .body-person {
          display: inline-block;
          height: fit-content;
          text-align: left;
          color: #205dc3;
          border-radius: 3px;
          line-height: 2;
          max-width: 54%;
          &.no {
            cursor: default;
            color: #999;
          }
        }

        .choose-btn {
          margin-left: 15px;
        }
      }

      &:last-child {
        .approval-title {
          margin-bottom: 0;
        }
      }
    }
  }
}

.content-body {
  // padding: 25px 30px;
  overflow-x: auto;
  .content-body-item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;
    .content-body-content {
      width: calc(100% - 65px);
      .content-html {
        img {
          width: 100% !important;
        }
      }
      img {
        max-width: 100%;
        width: fit-content;
        border-radius: 5px;
      }
    }
    .content-body-title {
      width: 65px;
      color: #666666;
    }
  }
}
::v-deep .rejectForm {
  .rejectTips {
    font-size: 18px;
    padding: 10px 0;
  }
  .el-dialog__body {
    padding: 10px 25px;
  }
  .el-form--inline .el-form-item__content,
  .el-form--inline .el-form-item {
    width: 100%;
  }
}
</style>
