<template>
  <div class="main">
    <div class="container">
      <el-breadcrumb separator=">" style="margin-bottom: 20px;">
        <el-breadcrumb-item
          :to="{
            path: '/government/sbzj',
          }"
          >申报征集</el-breadcrumb-item
        >
        <el-breadcrumb-item>数字化改造项目备案申报</el-breadcrumb-item>
      </el-breadcrumb>
      <el-tabs
        style="margin-top: 20px;"
        v-model="verifyType"
        @tab-click="handleChangeVerifyType"
      >
        <el-tab-pane label="已审批企业" name="yspqy"> </el-tab-pane>
        <!-- <el-tab-pane label="已拒绝企业" name="yjjqy"> </el-tab-pane> -->
        <el-tab-pane label="待审批企业" name="dspqy"> </el-tab-pane>
      </el-tabs>

      <div class="verify-form">
        <el-form :inline="true" :model="verifyForm_qy">
          <el-form-item label="企业名称">
            <el-input
              v-model="verifyForm_qy.enterprise_name"
              placeholder="请输入企业名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="是否已完成">
            <el-select v-model="verifyForm_qy.isComplete" placeholder="请选择">
              <el-option label="全部" value=""></el-option>
              <el-option label="未完成" :value="1"></el-option>
              <el-option label="完成" :value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button
              class="primaryBtn1"
              type="primary"
              @click="handleSubmit_qy"
              >查询</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <div class="verify-table">
        <template>
          <el-table
            :data="tableData_qy"
            border
            style="width: 100%"
            @selection-change="handleQYSelectionChange"
            :key="itemKey_qy"
          >
            <el-table-column type="selection" width="55" key="qy_select">
            </el-table-column>
            <el-table-column type="index" label="序号" width="55" key="qy_序号">
            </el-table-column>
            <el-table-column
              prop="companyName"
              label="公司名称"
              show-overflow-tooltip
              key="qy_公司名称"
            >
            </el-table-column>
            <el-table-column
              prop="baseInfo.ssq"
              label="所属区"
              show-overflow-tooltip
              key="qy_所属区"
            >
            </el-table-column>
            <el-table-column
              prop="baseInfo.lxr"
              label="联系人"
              show-overflow-tooltip
              key="qy_联系人"
            >
            </el-table-column>
            <el-table-column
              prop="baseInfo.lxfs"
              label="联系方式"
              show-overflow-tooltip
              key="qy_联系方式"
            >
            </el-table-column>
            <el-table-column
              prop="detailTrade"
              label="所属细分行业"
              show-overflow-tooltip
              key="qy_所属细分行业"
            >
            </el-table-column>
            <el-table-column
              prop="digitalCultivationProduct.projectName"
              label="项目名称"
              show-overflow-tooltip
              key="qy_项目名称"
            >
            </el-table-column>
            <el-table-column
              prop="digitalCultivationProduct.preInput"
              label="项目投入总金额（万元）"
              show-overflow-tooltip
              key="qy_项目投入总金额（万元）"
            >
            </el-table-column>
            <el-table-column
              prop="phone"
              label="项目实施期限"
              show-overflow-tooltip
              key="qy_项目实施期限"
            >
              <template slot-scope="scope">
                {{ scope.row.digitalCultivationProduct.startTime }}至{{
                  scope.row.digitalCultivationProduct.endTime
                }}
              </template>
            </el-table-column>
            <el-table-column
              prop="phone"
              label="项目实施进度"
              show-overflow-tooltip
              key="qy_项目实施进度"
            >
              <template slot-scope="scope">
                {{ scope.row.progress }}%
              </template>
            </el-table-column>
            <el-table-column label="备案状态" key="qy_备案状态">
              <template slot-scope="scope">
                <div v-if="!scope.row.auditReason">
                  <span v-if="scope.row.auditStatus == 1">
                    审核中
                  </span>
                  <span v-if="scope.row.auditStatus == 2">
                    已备案
                  </span>
                  <span v-if="scope.row.auditStatus == 3">
                    审核不通过
                  </span>
                </div>

                <el-tooltip
                  effect="dark"
                  :content="scope.row.auditReason"
                  placement="top-start"
                  v-else
                >
                  <span v-if="scope.row.auditStatus == 1">
                    审核中
                  </span>
                  <span v-if="scope.row.auditStatus == 2">
                    已备案
                  </span>
                  <span v-if="scope.row.auditStatus == 3">
                    审核不通过
                  </span>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column label="操作" key="qy_操作" width="218px">
              <template slot-scope="scope">
                <el-button type="text" @click="handleQYDetail(scope.row)"
                  >详情</el-button
                >
                <el-button
                  type="text"
                  @click="handleQYPassAndReject(scope.row, 2)"
                  v-if="scope.row.auditStatus == 1"
                  >通过</el-button
                >
                <el-button
                  type="text"
                  @click="handleQYPassAndReject(scope.row, 3)"
                  style="color: #FF1C1C;"
                  v-if="scope.row.auditStatus == 1"
                  >拒绝</el-button
                >
                <el-button
                  type="text"
                  @click="handleQYPassAndReject(scope.row, 4)"
                  style="color: #FFBB1A;"
                  v-if="scope.row.auditStatus != 3 && scope.row.isComplete != 2"
                  >完成</el-button
                >
                <el-button
                  type="text"
                  @click="handleExport(scope.row)"
                  style="color: #EA7100;"
                  >导出</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </template>
      </div>
      <div class="verify-pager flex bewteen vertical">
        <div style="width: 450px;margin: 10px 0;">
          <!-- <el-button
            v-if="verifyType == 'dspqy'"
            type="primary"
            @click="showVerifyFormMoreDialog('pass')"
            >批量通过</el-button
          >
          <el-button
            v-if="verifyType == 'dspqy'"
            type="danger"
            @click="showVerifyFormMoreDialog('reject')"
            >批量拒绝</el-button
          > -->
          <!-- <el-button type="primary" @click="doExportCompanyDigitalizeFile()"
            >批量导出</el-button
          > -->
        </div>
        <el-pagination
          background
          layout="prev, pager, next"
          :total="total_qy"
          :current-page="page_qy"
          :page-size="pageSize"
          @current-change="handleCurrentChange_qy"
        >
        </el-pagination>
      </div>
    </div>

    <!-- 弹窗开始   -->
    <el-dialog
      title="审核"
      :visible.sync="verifyFormDialog"
      width="500px"
      :before-close="handleCloseDialog"
      :close-on-click-modal="false"
      class="verifyForm"
    >
      <el-form :inline="true" :model="verifyForm" style="width: 100%;">
        <el-form-item>
          <el-input
            type="textarea"
            maxlength="150"
            rows="6"
            show-word-limit
            v-model="verifyForm.remark"
            placeholder="请输入原因"
          ></el-input>
        </el-form-item>
        <el-form-item v-if="verifyFormType == 'pass'">
          <el-select
            v-model="importCompanyName"
            filterable
            placeholder="请选择意向数字化牵引单位"
          >
            <el-option
              v-for="item in importCompanyList"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCloseDialog">取 消</el-button>
        <el-button type="primary" @click="doQYReject">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 弹窗结束 -->
    <!-- 弹窗开始   -->
    <el-dialog
      title="批量审核"
      :visible.sync="verifyFormMoreDialog"
      width="500px"
      :before-close="handleCloseDialog"
      :close-on-click-modal="false"
      class="verifyForm"
    >
      <el-form :inline="true" :model="verifyForm" style="width: 100%;">
        <el-form-item>
          <el-input
            type="textarea"
            maxlength="150"
            rows="6"
            show-word-limit
            v-model="verifyForm.remark"
            placeholder="请输入原因"
          ></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCloseDialog">取 消</el-button>
        <el-button type="primary" @click="idsVerify">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 弹窗结束 -->
  </div>
</template>
<script>
// import configs from "@/config/index";
import { formatDateTime } from "@/utils/common";
import {
  getCompanyDigitalizeObscureList,
  govementUpdateCompany,
  governmentBatchCheck,
  exportCompanyDigitalizeFile,
  getDigitalPage,
  auditDigital,
} from "@/api/zf";
import { exportDigitalProject } from "@/api/qy";
import { getArea } from "@/api/login";
export default {
  name: "ApprovalCenter",
  data() {
    return {
      verifyType: "yspqy",

      // 行业列表
      hyList: [
        {
          id: 1,
          name: "智能网联和新能源汽车（零部件）",
        },
        {
          id: 2,
          name: "工业母机和机器人",
        },
        {
          id: 3,
          name: "时尚美妆",
        },
        {
          id: 4,
          name: "定制家居",
        },
        {
          id: 5,
          name: "服装",
        },
        {
          id: 6,
          name: "箱包",
        },
      ],
      // 区域列表
      areaList: [],
      // 审核状态列表
      // statusList: ["待审核", "审核通过", "审核拒绝"],
      statusList: [
        { label: "待审核", value: 1 },
        { label: "审核通过", value: 2 },
        { label: "审核拒绝", value: 3 },
      ],

      // 搜索已审批企业
      verifyForm_qy: {
        enterprise_name: null,
        sub_industry: null,
        administrative_district: null,
        assess_lv: null,
        company: null,
        audit_status: 2,
        isComplete: null,
      },
      itemKey_qy: "",
      // 已审批企业表格
      tableData_qy: [],
      total_qy: 0,
      page_qy: 1,

      pageSize: 10,

      verifyForm: {
        remark: "",
      },
      verifyRow: {},
      verifyFormDialog: false,
      verifyFormType: "",
      // 企业下拉框
      importCompanyList: [
        {
          id: 1,
          name: "广州明珞装备股份有限公司",
        },
        {
          id: 2,
          name: "格创东智（广州）科技技术有限公司",
        },
        {
          id: 3,
          name: "浪潮云洲工业互联网有限公司",
        },
        {
          id: 4,
          name: "广州数控设备有限公司",
        },
        {
          id: 5,
          name: "树根互联股份有限公司",
        },
        {
          id: 6,
          name: "广东玛斯特智能系统有限公司",
        },
        {
          id: 7,
          name: "广州蜂巢互联科技有限公司",
        },
        {
          id: 8,
          name: "广州中浩控制技术有限公司",
        },
        {
          id: 9,
          name: "广州环亚化妆品科技股份有限公司",
        },
        {
          id: 10,
          name: "广州尚品宅配家居股份有限公司",
        },
        {
          id: 11,
          name: "欧派家居集团股份有限公司",
        },
        {
          id: 12,
          name: "广州鼎捷软件有限公司",
        },
        {
          id: 13,
          name: "比音勒芬服饰股份有限公司",
        },
        {
          id: 14,
          name: "广东省纺织品进出口股份有限公司",
        },
        {
          id: 15,
          name: "广州春晓信息科技有限公司",
        },
        {
          id: 16,
          name: "广州盖特软件有限公司",
        },
        {
          id: 17,
          name: "联通(广东)产业互联网有限公司",
        },
        {
          id: 18,
          name: "杭州海康威视数字技术股份有限公司",
        },
        {
          id: 19,
          name: "华为云计算技术有限公司",
        },
        {
          id: 20,
          name: "广东用友软件有限公司",
        },
        {
          id: 21,
          name: "金蝶软件(中国)有限公司",
        },
        {
          id: 22,
          name: "广东丸美生物技术股份有限公司",
        },
        {
          id: 23,
          name: "广州赛意信息科技股份有限公司",
        },
        {
          id: 24,
          name: "广东粤桨产业科技有限公司",
        },
      ],
      importCompanyName: "",

      //批量审核
      verifyFormMoreDialog: false,
      // 多选的id列表
      ids: [],
      // 多选的id列表状态
      idsStatus: [],

      // 是否正在进行审核操作
      isVerifing: false,
    };
  },
  created() {
    this.getVerifyList_qy();
    // this.hyList = JSON.parse(sessionStorage.getItem("hylist"));
    // this.areaList = configs.areaOptions;
    this.getGUANGZHOUAreaList();
  },
  mounted() {
    this.$nextTick(() => {});
  },
  methods: {
    handleExport(row) {
      console.log("row", row);
      let pdata = {
        id: row.id,
      };
      exportDigitalProject(pdata).then((res) => {
        if (res) {
          let fileName = "";
          if (res.type == "application/zip") {
            fileName = `数字化改造项目备案-${new Date().getTime()}.zip`;
          } else {
            fileName = `数字化改造项目备案-${new Date().getTime()}.docx`;
          }
          const blob = new Blob([res]);
          console.log("bolb", blob);
          console.log("bolb", res);

          // 获取heads中的filename文件名
          const downloadElement = document.createElement("a");
          // 创建下载的链接
          const href = window.URL.createObjectURL(blob);
          downloadElement.href = href;
          // 下载后文件名
          downloadElement.download = fileName;
          document.body.appendChild(downloadElement);
          // 点击下载
          downloadElement.click();
          // 下载完成移除元素
          document.body.removeChild(downloadElement);
          // 释放掉blob对象
          window.URL.revokeObjectURL(href);
        }
      });
    },
    auditDigital(id, auditStatus, auditReason) {
      let pdata = {
        id,
        auditStatus,
        auditReason,
      };
      auditDigital(pdata)
        .then((res) => {
          console.log("res", res);
          if (res.code == 200) {
            this.$message.success("操作成功");
          }
        })
        .finally(() => {
          this.getVerifyList_qy();
        });
    },
    doExportCompanyDigitalizeFile() {
      let _this = this;

      this.$confirm("是否批量导出?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let data = {
            type: 1,
            ids: JSON.stringify(_this.ids),
          };
          if (_this.ids.length == 0) {
            data.type = 2;
          }
          exportCompanyDigitalizeFile(data)
            .then((res) => {
              console.log(res, "------------");
              if (res) {
                const fileName = `试点意向企业入库申报-${new Date().getTime()}.xlsx`;
                const blob = new Blob([res]);
                // 获取heads中的filename文件名
                const downloadElement = document.createElement("a");
                // 创建下载的链接
                const href = window.URL.createObjectURL(blob);
                downloadElement.href = href;
                // 下载后文件名
                downloadElement.download = fileName;
                document.body.appendChild(downloadElement);
                // 点击下载
                downloadElement.click();
                // 下载完成移除元素
                document.body.removeChild(downloadElement);
                // 释放掉blob对象
                window.URL.revokeObjectURL(href);
              }
            })
            .catch((err) => {
              console.log(err);

              this.$message({
                type: "error",
                message: "导出失败",
              });
            });
        })
        .catch(() => {
          _this.isVerifing = false;
          _this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },

    showVerifyFormMoreDialog(type) {
      let _this = this;
      if (
        _this.idsStatus.indexOf(2) != -1 ||
        _this.idsStatus.indexOf(3) != -1
      ) {
        _this.$message.error("不可审核已通过/已拒绝的审批");
        return false;
      }

      this.$confirm("是否批量通过审批?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.verifyFormMoreDialog = true;
          this.verifyFormType = type;
        })
        .catch(() => {
          _this.isVerifing = false;
          _this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    idsVerify() {
      let _this = this;
      if (this.isVerifing) {
        return false;
      }
      this.isVerifing = true;
      console.log(123);

      let pdata = {
        // ids: _this.ids.join(","),
        ids: JSON.stringify(_this.ids),
        audit_reason: _this.verifyForm.remark,
        audit_status: 1,
      };
      if (_this.verifyFormType == "pass") {
        pdata.audit_status = 2;
      } else if (_this.verifyFormType == "reject") {
        pdata.audit_status = 3;
      }
      governmentBatchCheck(pdata)
        .then((res) => {
          console.log("res", res);

          _this.$message({
            type: "success",
            message: "操作成功",
          });
        })
        .catch((err) => {
          console.log("error!", err);

          _this.$message({
            type: "error",
            message: "操作失败",
          });
        })
        .finally(() => {
          _this.handleSubmit_qy();
          _this.isVerifing = false;
          _this.handleCloseDialog();
        });
    },
    handleQYSelectionChange(items) {
      console.log("items----------->", items);

      this.ids = items.map((item) => {
        return item.id;
      });
      this.idsStatus = items.map((item) => {
        return item.company_digitalize_agree_select.audit_status;
      });
    },

    doQYReject() {
      // 如果还在进行审核操作，return
      if (this.isVerifing) {
        return false;
      }
      this.isVerifing = true;

      let _this = this;
      let data = {
        audit_status: 1, //审核状态 1：待审核 2：审核通过 3：审核不通过
        audit_reason: this.verifyForm.remark, //审核不通过原因
        govement_select: this.importCompanyName,
      };
      if (this.verifyFormType == "pass") {
        data.audit_status = 2;
      } else if (this.verifyFormType == "reject") {
        data.audit_status = 3;
      }

      govementUpdateCompany({
        id: this.verifyRow.id, //该条产品申报id
        company_digitalize_agree_select: JSON.stringify(data),
      })
        .then((res) => {
          // console.log(res);
          if (res.code == 1) {
            this.$message.success("操作成功");
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log("error!", err);
          _this.$message({
            type: "error",
            message: "操作失败",
          });
        })
        .finally(() => {
          _this.handleCloseDialog();
          _this.handleSubmit_qy();
          _this.isVerifing = false;
        });
    },
    handleCloseDialog() {
      this.verifyFormDialog = false;
      this.verifyForm = {};
      this.verifyRow = {};
      this.verifyFormType = "";
      this.importCompanyName = "";
      this.ids = [];
      this.idsStatus = [];
      this.verifyFormMoreDialog = false;
    },
    handleQYPassAndReject(row, type) {
      if (type == 4) {
        let pdata = {
          id: row.id,
          auditStatus: row.auditStatus,
          isComplete: 2,
        };
        auditDigital(pdata)
          .then((res) => {
            console.log("res", res);
            if (res.code == 200) {
              this.$message.success("操作成功");
            }
          })
          .finally(() => {
            this.getVerifyList_qy();
          });
      }
      //  else if (type == 5) {
      //   let pdata = {
      //     id: row.id,
      //     auditStatus: row.auditStatus,
      //     isComplete: 1,
      //   };
      //   auditDigital(pdata).then((res) => {
      //     console.log("res", res);
      //   });
      // }
      else {
        this.$prompt("请输入审核理由", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        })
          .then(({ value }) => {
            console.log("value", value);
            this.auditDigital(row.id, type, value);
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "取消操作",
            });
          });
      }
    },

    //跳转问卷详情
    handleQYDetail(row) {
      this.$router.push({
        path: "/government/sbzj/formThird",
        query: {
          type: 2,
          id: row.id,
        },
      });
    },

    //获取table数据
    getVerifyList_qy() {
      let pdata = {
        // req: JSON.stringify(this.verifyForm_qy),
        isComplete: this.verifyForm_qy.isComplete,
        auditStatus: this.verifyForm_qy.audit_status,
        pageNum: this.page_qy,
        pageSize: 10,
      };
      if (
        this.verifyForm_qy.enterprise_name != "null" &&
        this.verifyForm_qy.enterprise_name != null &&
        this.verifyForm_qy.enterprise_name != ""
      ) {
        pdata.companyName = this.verifyForm_qy.enterprise_name;
      }
      if (
        this.verifyForm_qy.isComplete == "null" ||
        this.verifyForm_qy.isComplete == null ||
        this.verifyForm_qy.isComplete == ""
      ) {
        delete pdata.isComplete;
      }
      getDigitalPage(pdata).then((res) => {
        console.log("res", res);
        this.tableData_qy = res.data.list.map((item) => {
          return {
            ...item,
            baseInfo: JSON.parse(item.baseInfo),
          };
        });
        this.total_qy = res.data.total;
        this.itemKey_qy = Math.random() + "_qy";
      });
    },
    getGUANGZHOUAreaList() {
      let pdata = {
        pid: 236,
      };

      getArea(pdata).then((res) => {
        console.log("res", res);

        this.areaList = res.data;
      });
    },
    // 分页器pagechange
    handleCurrentChange_qy(page) {
      this.page_qy = page;
      if (this.verifyType == "yspqy") {
        // this.verifyForm_qy.audit_status = 2;
        delete this.verifyForm_qy.audit_status;
        this.getVerifyList_qy();
      } else if (this.verifyType == "dspqy") {
        this.verifyForm_qy.audit_status = 1;
        this.getVerifyList_qy();
      }
      // else if (obj.name == "yjjqy") {
      //   this.verifyForm_qy.audit_status = 3;
      //   this.getVerifyList_qy();
      // }
    },
    // 查询
    handleSubmit_qy() {
      if (this.verifyType == "yspqy") {
        // this.verifyForm_qy.audit_status = 2;
        delete this.verifyForm_qy.audit_status;
        this.getVerifyList_qy();
      } else if (this.verifyType == "dspqy") {
        this.verifyForm_qy.audit_status = 1;
        this.getVerifyList_qy();
      }
      // else if (obj.name == "yjjqy") {
      //   this.verifyForm_qy.audit_status = 3;
      //   this.getVerifyList_qy();
      // }
    },
    formatDateTime(timetemp) {
      return formatDateTime(timetemp);
    },
    handleChangeVerifyType(obj) {
      // 每次切换都发一次请求 相当于刷新
      this.page_qy = 1;
      this.tableData_qy = [];
      this.verifyForm_qy = {};
      if (obj.name == "yspqy") {
        // this.verifyForm_qy.audit_status = 2;
        delete this.verifyForm_qy.audit_status;
        this.getVerifyList_qy();
      } else if (obj.name == "dspqy") {
        this.verifyForm_qy.audit_status = 1;
        this.getVerifyList_qy();
      }
      // else if (obj.name == "yjjqy") {
      //   this.verifyForm_qy.audit_status = 3;
      //   this.getVerifyList_qy();
      // }
      this.verifyType = obj.name;
    },
  },
};
</script>
<style lang="less" scoped>
::v-deep .el-pagination {
  width: 100%;
  margin: 10px auto;
  text-align: right;
  box-sizing: border-box;
}
::v-deep .el-table td.el-table__cell div {
  text-align: center;
}
::v-deep .el-tabs__item {
  color: #666;
  font-size: 22px;
  margin-bottom: 8px;
  &.is-active {
    color: #333;
    font-weight: 600;
  }
  &:not(.is-active):hover {
    color: #666;
  }
}
::v-deep .el-tabs__active-bar {
  height: 3px;
  // margin-top: 8px;
}
::v-deep .el-tabs__nav-wrap::after {
  height: 1px;
  background-color: #d8d8d8;
}
::v-deep .el-form-item {
  margin-bottom: 10px;
}
::v-deep .el-table th.el-table__cell > .cell {
  text-align: center;
}
::v-deep .el-table th.el-table__cell.is-leaf {
  color: #333;
  background-color: #eef2ff;
  border-color: #d8d8d8;
}
::v-deep .el-table--border {
  border-color: #d8d8d8;
}
.main {
  .container {
    width: 84%;
    margin: 0 auto;
    padding: 30px 25px 0;
    background-color: #fff;
    border-radius: 10px;
    box-sizing: border-box;
    overflow: hidden;
  }
}
.approval-main {
  border-left: 2px solid #205dc3;
  margin: 20px 50px;

  .approval-list {
    padding-left: 50px;
    .approval-item {
      .approval-title {
        position: relative;

        color: #666;
        font-size: 16px;
        line-height: 16px;
        margin-bottom: 20px;
        margin-top: 20px;

        &::before {
          content: "";
          width: 16px;
          height: 16px;
          border-radius: 18px;
          background-color: #205dc3;

          position: absolute;
          left: -59px;
          top: 0;
          bottom: 0;
          margin: auto 0;
        }
      }
      .approval-body {
        margin-bottom: 10px;
        font-size: 16px;
        line-height: 32px;
        display: flex;
        align-items: flex-start;
        .body-status {
          display: inline-block;
          // width: 150px;
          line-height: 1;
          padding: 3px 8px;
          text-align: center;
          border-radius: 3px;
          font-size: 12px;
          margin: 6px 0 0 15px;
          &.wait {
            color: #666;
            border: 1px solid #666;
          }
          &.pass {
            color: #67c23a;
            border: 1px solid #67c23a;
          }
          &.reject {
            color: #f56c6c;
            border: 1px solid #f56c6c;
          }
        }
        .body-time {
          margin-left: 15px;
          font-size: 12px;
          color: #666;
        }
        .body-person {
          display: inline-block;
          height: fit-content;
          text-align: left;
          color: #205dc3;
          border-radius: 3px;
          line-height: 2;
          max-width: 54%;
          &.no {
            cursor: default;
            color: #999;
          }
        }

        .choose-btn {
          margin-left: 15px;
        }
      }

      &:last-child {
        .approval-title {
          margin-bottom: 0;
        }
      }
    }
  }
}

.content-body {
  // padding: 25px 30px;
  overflow-x: auto;
  .content-body-item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;
    .content-body-content {
      width: calc(100% - 65px);
      .content-html {
        img {
          width: 100% !important;
        }
      }
      img {
        max-width: 100%;
        width: fit-content;
        border-radius: 5px;
      }
    }
    .content-body-title {
      width: 65px;
      color: #666666;
    }
  }
}
::v-deep .verifyForm {
  .rejectTips {
    font-size: 18px;
    padding: 10px 0;
  }
  .el-dialog__body {
    padding: 10px 25px;
  }
  .el-form--inline .el-form-item__content,
  .el-form--inline .el-form-item {
    width: 100%;
  }
}
</style>
